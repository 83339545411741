import { AccountInterface } from "./AccountInterface";

export interface CoordinatesInterface {
  lat: number;
  lng: number;
}

export interface LocationInterface {
  locationID?: number;
  name: string;
  code?: string;
  address: string;
  reference?: string;
  postalCode?: string;
  coordinates: CoordinatesInterface;
}

export enum BusinessType {
  EXTERNAL = 10,
  INTERNAL = 20,
  JUNIOR = 30,
  PLATAFORM = 40,
  TRANSPORTIST = 50,
  PORT = 60,
  CARRIER = 70,
  INT_OFFICE = 80,
  USA_AGENT = 90,
  ESP_AGENT = 100,
  PANAMA = 110,
  COMMERCIAL_ALLY = 120,
  RETURN_WAREHOUSE = 130,
  COMMERCIAL_VIRTUAL_STORE = 140,
  ADMINISTRATIVE_UNIT = 150,
  VIRTUAL_STORE_FOR_INTERNAL_USE = 160,
}

export interface BusinessUnitInterface {
  id: string;
  name: string;
  code: string;
  type?: BusinessType;
  location: LocationInterface;
  isLocked?: boolean;
  polygonList: CoordinatesInterface[][];
}

export interface DestinationInterface {
  client: AccountInterface;
  location: LocationInterface;
  businessUnit: BusinessUnitInterface;
  homeDelivery: boolean;
}

export interface DestinationListInterface {
  AccountID: string;
  destinations: DestinationInterface[];
}
export interface FavAndHistoricalDestinationListInterface {
  accountID: string;
  favoriteDestinations: DestinationInterface[];
  historicalDestinations: DestinationInterface[];
}
export interface WhiteLocationInterface {
  accountID: string;
  consigneeDestinationList: DestinationInterface[];
}
export interface FavoriteLocationInterface extends DestinationListInterface {}
export interface HistoricalLocationInterface extends DestinationListInterface {}
