import React, { FC, useEffect, useMemo, useState } from "react";
import { PaymentMode, TaxInterface } from "../../interfaces";
import {
  currencyExchangeText,
  paymentModeFormat,
  useCurrencyExchanges,
} from "../../utils";
import { LinkText } from "../Buttons";
import { useAppSelector } from "../../store/hooks";
import { getBUCredit } from "../../services";
import { BUCreditDTO } from "../../interfaces/Dtos/BUCreditDTO";
import classNames from "classnames";

export interface PaymentTotalProps {
  showEstimate?: boolean;
  paymentMode?: PaymentMode;
  subTotal: number;
  total?: number;
  remaining?: number;
  baseRemaining?: number;
  taxes: TaxInterface[];
  allowPaymentModeChange?: boolean;
  className?: string;
  onChangePaymentMode?: () => void;
}

const PaymentTotal: FC<PaymentTotalProps> = ({
  showEstimate,
  paymentMode,
  subTotal,
  total,
  taxes,
  remaining,
  baseRemaining,
  allowPaymentModeChange,
  className,
  onChangePaymentMode,
}) => {
  const exchanges = useCurrencyExchanges();
  const user = useAppSelector((state) => state.user);

  const [buCredit, setBuCredit] = useState<BUCreditDTO>();

  const realTotal = useMemo(() => {
    if (total !== undefined) return total;

    const totalTaxes = taxes.reduce((acc, tax) => {
      if (tax.type === "percentage") {
        return acc + subTotal * (tax.value / 100);
      }
      return acc + tax.value;
    }, 0);

    return subTotal + totalTaxes;
  }, [subTotal, taxes, total]);

  const realTaxes = useMemo(() => {
    // Join and sum taxes with same value
    const taxesWithSameValue: TaxInterface[] = [];
    taxes.forEach((tax) => {
      const index = taxesWithSameValue.findIndex(
        (taxWithSameValue) => taxWithSameValue.name === tax.name
      );
      if (index === -1) {
        taxesWithSameValue.push({ ...tax });
      } else {
        taxesWithSameValue[index].value += tax.value;
      }
    });

    return taxesWithSameValue;
  }, [taxes]);

  const realRemaining = useMemo(() => {
    if (baseRemaining !== undefined) {
      return {
        usd: currencyExchangeText(
          Math.max(0, baseRemaining),
          exchanges,
          "USD",
          "BS"
        ),
        bs: currencyExchangeText(
          Math.max(0, baseRemaining),
          exchanges,
          "BS",
          "BS"
        ),
      };
    } else if (remaining !== undefined) {
      return {
        usd: currencyExchangeText(
          Math.max(0, remaining),
          exchanges,
          "USD",
          "BS"
        ),
        bs: currencyExchangeText(Math.max(0, remaining), exchanges, "BS", "BS"),
      };
    }
    return undefined;
  }, [remaining, baseRemaining, exchanges]);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode || !allowPaymentModeChange) return;

    const getCredit = async () => {
      const response = await getBUCredit(buCode);
      if (!response.didError && response.model) {
        setBuCredit(response.model);
      }
    };

    getCredit();
  }, [user.businessUnit, allowPaymentModeChange]);

  return (
    <div
      className={classNames(
        "flex flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-4",
        className
      )}
    >
      <div className="flex flex-1 flex-col">
        {!!paymentMode && (
          <div className="flex flex-col">
            <p className="text-gray-400">Modalidad de Pago</p>
            <p className="font-semibold text-xl">
              {paymentModeFormat(paymentMode)}
            </p>
          </div>
        )}
        {allowPaymentModeChange && paymentMode === PaymentMode.COD && (
          <div className="flex flex-col mt-2 text-sm">
            <LinkText
              text="Cambiar modalidad de pago a Crédito Taquilla"
              disabled={(buCredit?.balance ?? 0) <= 0}
              onClick={onChangePaymentMode}
            />
            <p className="text-gray-600 text-xs">
              Crédito disponible:{" "}
              {currencyExchangeText(
                buCredit?.balance ?? 0,
                exchanges,
                "USD",
                buCredit?.currencyID === 1 ? "BS" : "USD"
              )}
            </p>
          </div>
        )}
      </div>

      <div className="flex flex-1 flex-col">
        <div className="flex flex-row justify-between">
          <p className="text-gray-400">Sub total</p>
          <div className="flex flex-col items-end">
            <p className="font-semibold text-gray-600">
              {currencyExchangeText(subTotal, exchanges, "USD")}
            </p>
            <p className="text-xs text-gray-400 ">
              {currencyExchangeText(subTotal, exchanges, "BS")}
            </p>
          </div>
        </div>
        <hr className="my-2" />

        {realTaxes.map((tax, i) => {
          const value =
            tax.type === "percentage"
              ? subTotal * (tax.value / 100)
              : tax.value;

          return (
            <React.Fragment key={i}>
              <div className="flex flex-row justify-between">
                <p className="text-gray-400">{tax.name}</p>
                <div className="flex flex-col items-end">
                  <p className="font-semibold text-gray-600">
                    {currencyExchangeText(value, exchanges, "USD")}
                  </p>
                  <p className="text-xs text-gray-400 ">
                    {currencyExchangeText(value, exchanges, "BS")}
                  </p>
                </div>
              </div>
              <hr className="my-2" />
            </React.Fragment>
          );
        })}

        <div className="flex flex-row justify-between">
          <p className="text-gray-400">Total de factura</p>
          <div className="flex flex-col items-end">
            <p className="font-semibold text-gray-600">
              {currencyExchangeText(realTotal, exchanges, "USD")}
            </p>
            <p className="text-xs text-gray-400 ">
              {currencyExchangeText(realTotal, exchanges, "BS")}
            </p>
          </div>
        </div>

        {!!realRemaining ? (
          <div className="flex flex-col items-end justify-end mt-4 gap-4">
            <p className="text-sm text-gray-400">Total por pagar:</p>
            <div className="flex flex-col items-end">
              <p className="font-semibold text-2xl">{realRemaining.usd}</p>
              <p className="text-xs text-gray-400 ">{realRemaining.bs}</p>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-end justify-end mt-4 gap-4">
            <p className="text-sm text-gray-400">Total:</p>
            <div className="flex flex-col items-end">
              <p className="font-semibold text-2xl">
                {currencyExchangeText(realTotal, exchanges, "USD")}
              </p>
              <p className="text-xs text-gray-400 ">
                {currencyExchangeText(realTotal, exchanges, "BS")}
              </p>
            </div>
          </div>
        )}

        {showEstimate && (
          <p className="text-xs text-right text-gray-400">
            *Los montos son estimados.
          </p>
        )}
      </div>
    </div>
  );
};

export default PaymentTotal;
