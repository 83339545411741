import { Dispatch, SetStateAction, useMemo, useState } from "react";
import classNames from "classnames";
import Disclosure from "./Disclosure";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  FormMultipleSimpleRadioGroup,
  FormSimpleRadioGroup,
  MultipleRadioOption,
} from "./FormFields";
import { LinkText } from "./Buttons";

interface SimpleFilterDisclosureProps<T> {
  title: string;
  selected?: T;
  options: { name: string; value: T }[];
  disabled?: boolean;
  onSelectOption: (opt: T) => void;
}
export const SimpleFilterDisclosure = <T extends any>({
  title,
  options,
  selected,
  disabled,
  onSelectOption,
}: SimpleFilterDisclosureProps<T>) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <h3 className="flow-root">
        <button
          onClick={() => !open && setOpen(true)}
          className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400"
        >
          <span className="font-medium text-gray-900">{title}</span>
          <span className="ml-2 flex items-center">
            <ChevronDownIcon
              className={classNames(
                open ? "-rotate-180" : "rotate-0",
                "h-5 w-5 transform"
              )}
              aria-hidden="true"
            />
          </span>
        </button>
      </h3>

      <Disclosure open={open} setOpen={setOpen} className="-mt-1">
        <div className="px-4 py-2">
          <FormSimpleRadioGroup
            options={options}
            disabled={disabled}
            selected={selected}
            onSelectOption={onSelectOption}
          />
        </div>
      </Disclosure>
    </div>
  );
};

interface MultipleFilterDisclosureProps<T> {
  title: string;
  options: MultipleRadioOption<T>[];
  disabled?: boolean;
  showCount?: boolean;
  setOptions: Dispatch<SetStateAction<MultipleRadioOption<T>[]>>;
}
export const MultipleFilterDisclosure = <T extends any>({
  title,
  options,
  disabled,
  showCount,
  setOptions,
}: MultipleFilterDisclosureProps<T>) => {
  const [open, setOpen] = useState(false);

  const count = useMemo(() => {
    return options.reduce((acc, opt) => acc + +opt.checked, 0);
  }, [options]);

  const clear = () => {
    setOptions(options.map((opt) => ({ ...opt, checked: false })));
  };

  return (
    <div>
      <h3 className="flow-root">
        <button
          onClick={() => !open && setOpen(true)}
          className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400"
        >
          <span className="font-medium text-gray-900">{title}</span>

          {showCount && (
            <span className="ml-1.5 rounded bg-indigo-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
              {count}
            </span>
          )}

          <span className="ml-2 flex items-center">
            <ChevronDownIcon
              className={classNames(
                open ? "-rotate-180" : "rotate-0",
                "h-5 w-5 transform"
              )}
              aria-hidden="true"
            />
          </span>
        </button>
      </h3>

      <Disclosure open={open} setOpen={setOpen} className="-mt-1">
        <div className="px-4 py-2">
          <FormMultipleSimpleRadioGroup
            options={options}
            disabled={disabled}
            setOptions={setOptions}
            optionsClassName="text-sm text-gray-900"
          />
        </div>

        <div className="flex flex-1 items-center justify-center">
          <LinkText text="Limpiar" className="text-sm my-2" onClick={clear} />
        </div>
      </Disclosure>
    </div>
  );
};

export interface MixedFilter<T> {
  title: string;
  isMultiple: boolean;
  selected?: T;
  simpleOptions?: { name: string; value: T }[];
  multipleOptions?: MultipleRadioOption<T>[];
  disabled?: boolean;
  onSelectSimpleOption?: (opt: T) => void;
  onSelectMultipleOption?: Dispatch<SetStateAction<MultipleRadioOption<T>[]>>;
}
interface MixedFilterDisclosureProps {
  title: string;
  filters: MixedFilter<any>[];
  showCount?: boolean;
  showClear?: boolean;
  className?: string;
}
export const MixedFilterDisclosure = ({
  title,
  filters,
  showCount,
  showClear,
  className,
}: MixedFilterDisclosureProps) => {
  const [open, setOpen] = useState(false);

  const count = useMemo(() => {
    return filters
      .filter((f) => f.isMultiple)
      .reduce((acc, f) => {
        return (
          acc +
          (f.multipleOptions?.reduce((acc, opt) => acc + +opt.checked, 0) || 0)
        );
      }, 0);
  }, [filters]);

  const clear = () => {
    filters.forEach((f) => {
      if (f.isMultiple) {
        f.onSelectMultipleOption?.(
          f.multipleOptions!.map((opt) => ({ ...opt, checked: false }))
        );
      } else {
        f.onSelectSimpleOption?.(f.simpleOptions![0].value);
      }
    });
  };

  return (
    <div>
      <h3 className="flow-root">
        <button
          onClick={() => !open && setOpen(true)}
          className={classNames(
            "flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400",
            className
          )}
        >
          <span className="font-medium text-gray-900">{title}</span>

          {showCount && (
            <span className="ml-1.5 rounded bg-indigo-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
              {count}
            </span>
          )}

          <span className="ml-2 flex items-center">
            <ChevronDownIcon
              className={classNames(
                open ? "-rotate-180" : "rotate-0",
                "h-5 w-5 transform"
              )}
              aria-hidden="true"
            />
          </span>
        </button>
      </h3>

      <Disclosure open={open} setOpen={setOpen} className="-mt-1 min-w-min">
        <div
          className="grid p-4 gap-4 pt-2"
          style={{
            gridTemplateColumns: "1fr ".repeat(filters.length / 2),
          }}
        >
          {filters.map((f, index) => (
            <div
              key={index}
              className={classNames(
                "min-w-min",
                index > 1 && "border-t border-gray-300"
              )}
            >
              <div className={classNames("min-w-min pt-2")}>
                <div className="flex flex-1 py-1 mb-1">
                  <span className="text-sm font-medium text-gray-900">
                    {f.title}
                  </span>
                </div>

                <div className="pl-1">
                  {f.isMultiple ? (
                    <FormMultipleSimpleRadioGroup
                      options={f.multipleOptions!}
                      disabled={f.disabled}
                      setOptions={f.onSelectMultipleOption!}
                      optionsClassName="text-xs text-gray-900"
                      wrapperClassName="!gap-2"
                    />
                  ) : (
                    <FormSimpleRadioGroup
                      options={f.simpleOptions!}
                      disabled={f.disabled}
                      selected={f.selected}
                      onSelectOption={f.onSelectSimpleOption!}
                      optionsClassName="text-xs text-gray-900"
                      wrapperClassName="!gap-2"
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div
          className={classNames(
            "flex flex-1 items-center justify-center py-3",
            !showClear && "hidden"
          )}
        >
          <LinkText text="Limpiar" className="text-sm" onClick={clear} />
        </div>
      </Disclosure>
    </div>
  );
};
