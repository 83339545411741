import { useState } from "react";
import { previewFileDO, updateFileDO, uploadFileDO } from "../services";
import { useAppSelector } from "../store/hooks";

const DOtest = () => {
  const [file, setFile] = useState<File>();
  const [key, setKey] = useState<string>("");
  const user = useAppSelector((state) => state.user.user);

  return (
    <div className="flex-col bg-white shadow-md rounded-md px-8 py-8 w-4/5">
      <h1>Digital Ocean Spaces</h1>
      {/* Upload */}
      <div className="px-8 py-8 shadow-md rounded-md">
        <input type="file" onChange={(e) => setFile(e.target.files![0])} />
        <button
          className="rounded-sm bg-blue-600 text-white px-4 py-2 mx-2"
          disabled={!file}
          onClick={async () => {
            const res = await uploadFileDO(file!, 1, 1, user?.userLogin!);
          }}
        >
          Upload
        </button>
      </div>
      {/* Preview */}
      <div className="px-8 py-8 shadow-md rounded-md">
        <input type="text" onChange={(e) => setKey(e.target.value)} />
        <button
          className="rounded-sm bg-blue-600 text-white px-4 py-2 mx-2"
          onClick={() => previewFileDO(key)}
        >
          Preview
        </button>
      </div>
      {/* Update */}
      <div className="px-8 py-8 shadow-md rounded-md">
        <input
          type="text"
          className="mr-2"
          onChange={(e) => setKey(e.target.value)}
        />
        <input type="file" onChange={(e) => setFile(e.target.files![0])} />
        <button
          className="rounded-sm bg-blue-600 text-white px-4 py-2 mx-2"
          disabled={!file}
          onClick={async () => {
            const res = await updateFileDO(file!, key);
            console.log("Actualizado:", res);
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default DOtest;
