import { FC, useMemo } from "react";
import classNames from "classnames";
import { FormCheckbox } from "../FormFields";
import { formatName, shipmentStatusFormat } from "../../utils/format";
import { PaymentMode, DocumentStatus } from "../../interfaces";
import { useAppSelector } from "../../store/hooks";
import { StoreShipmentInterface } from "../../interfaces/Dtos";

export interface ShipmentTableCheckProps {
  fixed?: string;
  shipments: StoreShipmentInterface[];
  selectedShipmentNumbers: string[];
  setSelectedShipmentNumbers?: (ids: string[]) => void;
}

const ShipmentTableCheck: FC<ShipmentTableCheckProps> = ({
  fixed,
  shipments,
  selectedShipmentNumbers,
  setSelectedShipmentNumbers = () => {},
}) => {
  const user = useAppSelector((state) => state.user);

  const handleSelectAll = () => {
    if (selectedShipmentNumbers.length === shipments.length) {
      setSelectedShipmentNumbers(fixed ? [fixed] : []);
    } else {
      setSelectedShipmentNumbers(shipments.map((s) => s.shipmentNumber));
    }
  };

  const shipmentFixed = useMemo(() => {
    return shipments.find((s) => s.shipmentNumber === fixed);
  }, [shipments, fixed]);

  const shipmentsToShow = useMemo(() => {
    return shipments.filter(
      (s) =>
        s.shipmentHeaderID !== shipmentFixed?.shipmentHeaderID &&
        s.paymentModeID === shipmentFixed?.paymentModeID &&
        // s.lastBU === user?.businessUnit?.code &&
        s.consigneeIdentificationNumber ===
          shipmentFixed?.consigneeIdentificationNumber &&
        // If the fixed shipment is paid, then the rest must be paid
        (shipmentFixed?.documentStatusID !== DocumentStatus.PAID ||
          s.documentStatusID === DocumentStatus.PAID) &&
        // if the fixed shipment does have a document, then the rest must have the same document
        (!shipmentFixed?.documentID ||
          s.documentID === shipmentFixed?.documentID) &&
        // If the fixed shipment does not have a document, then the rest
        // must be pending (or not have a document) and COD.
        (!!shipmentFixed?.documentID ||
          ((!s.documentID || s.documentStatusID === DocumentStatus.PENDING) &&
            s.paymentModeID === PaymentMode.COD)) &&
        // If the fixed shipment is pending and is COD, then the rest must be
        // pending
        (shipmentFixed?.documentStatusID !== DocumentStatus.PENDING ||
          shipmentFixed?.paymentModeID !== PaymentMode.COD ||
          s.documentStatusID === DocumentStatus.PENDING) &&
        // If the fixed shipment is partial paid and COD, then the rest must be
        // on the same document
        (shipmentFixed?.documentStatusID !== DocumentStatus.PARTIAL_PAID ||
          shipmentFixed?.paymentModeID === PaymentMode.COD ||
          s.documentID === shipmentFixed?.documentID)
    );
  }, [shipments, shipmentFixed]);

  const allSelected = useMemo(() => {
    return shipments.every((shipment) =>
      selectedShipmentNumbers.some((n) => n === shipment.shipmentNumber)
    );
  }, [shipments, selectedShipmentNumbers]);

  return (
    <div className="flex flex-1 flex-col bg-white rounded-lg border px-8 pb-6 pt-4 overflow-hidden max-w-[80vw]">
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs w-5">
                <FormCheckbox
                  id={`select-all`}
                  name={`select-all`}
                  label=""
                  onChange={handleSelectAll}
                  checked={allSelected}
                />
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                GUÍA
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                REMITENTE
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                ORIGEN
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                DESTINATARIO
              </th>

              <th className="text-left px-4 py-2 font-semibold text-xs">
                ESTADO
              </th>
            </tr>
          </thead>
          <tbody>
            {!!shipmentFixed && (
              <tr className={classNames("hover:bg-gray-100 bg-gray-50")}>
                <td className="text-xs px-4 py-4 flex items-end justify-end">
                  <FormCheckbox
                    checked
                    label=""
                    onChange={() => {}}
                    id={`select-${shipmentFixed.shipmentNumber}`}
                    name={`select-${shipmentFixed.shipmentNumber}`}
                  />
                </td>

                <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left">
                  {shipmentFixed.shipmentNumber}
                </td>

                <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left truncate">
                  {formatName(shipmentFixed.shipperName)}
                </td>

                <td className="text-xs px-4 py-4 font-semibold text-gray-500 truncate">
                  {shipmentFixed.buCodeSource} -{" "}
                  {shipmentFixed.sourceBUAreaName}
                </td>

                <td className="text-xs px-4 py-4 font-semibold text-gray-500 truncate text-left truncate">
                  {formatName(shipmentFixed.consigneeName)}
                </td>

                <td className="text-xs px-4 py-4 font-semibold text-gray-500 truncate">
                  {shipmentStatusFormat(shipmentFixed.statusID)}
                </td>
              </tr>
            )}

            {shipmentsToShow.map((shipment, index) => {
              const selected = selectedShipmentNumbers.some(
                (n) => n === shipment.shipmentNumber
              );

              return (
                <tr
                  key={index}
                  className={classNames(
                    "hover:bg-gray-100",
                    index % 2 === 1 && "bg-gray-50"
                  )}
                >
                  <td className="text-xs px-4 py-4 flex items-end justify-end">
                    <FormCheckbox
                      id={`select-${shipment.shipmentNumber}-${index}`}
                      name={`select-${shipment.shipmentNumber}-${index}`}
                      label=""
                      onChange={() => {
                        if (selected) {
                          setSelectedShipmentNumbers(
                            selectedShipmentNumbers.filter(
                              (n) => n !== shipment.shipmentNumber
                            )
                          );
                        } else {
                          setSelectedShipmentNumbers([
                            ...selectedShipmentNumbers,
                            shipment.shipmentNumber,
                          ]);
                        }
                      }}
                      checked={selected}
                    />
                  </td>

                  <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left">
                    {shipment.shipmentNumber}
                  </td>

                  <td className="text-xs px-4 py-4 font-bold text-gray-500 truncate text-left truncate max-w-[15rem]">
                    {formatName(shipment.shipperName)}
                  </td>

                  <td className="text-xs px-4 py-4 font-semibold text-gray-500 truncate max-w-[15rem]">
                    {shipment.buCodeSource} - {shipment.sourceBUAreaName}
                  </td>

                  <td className="text-xs px-4 py-4 font-semibold text-gray-500 truncate text-left truncate max-w-[15rem]">
                    {formatName(shipment.consigneeName)}
                  </td>

                  <td className="text-xs px-4 py-4 font-semibold text-gray-500 truncate">
                    {shipmentStatusFormat(shipment.statusID)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShipmentTableCheck;
