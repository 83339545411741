import React, { useCallback, useEffect, useRef } from "react";
import { v4 } from "uuid";
import classNames from "classnames";

interface DisclosureProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  className?: string;
  children: React.ReactNode;
}
const Disclosure = ({
  open,
  setOpen,
  className,
  children,
}: DisclosureProps) => {
  const id = v4();
  const openRef = useRef(open);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    },
    [ref, setOpen]
  );

  useEffect(() => {
    openRef.current = open;
  }, [open]);

  useEffect(() => {
    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const disclosure = document.getElementById(`disclosure-${id}`);
    if (!disclosure) return;

    const triggerRect = disclosure.getBoundingClientRect();
    const disclosureHeight = disclosure.offsetHeight;

    let disclosureTop = triggerRect.top;

    if (disclosureTop + disclosureHeight + 10 > window.innerHeight) {
      disclosureTop = triggerRect.top - disclosureHeight;
      if (disclosureTop < 0) {
        disclosure.style.marginTop = "0";
      } else {
        disclosure.style.marginTop = `calc(-${disclosureHeight}px - 2.5rem)`;
      }
    } else {
      disclosure.style.marginTop = "0";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div className="relative" ref={ref}>
      <div
        id={`disclosure-${id}`}
        className={classNames(
          "absolute right-0 z-10 mt-2 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
          !open && "hidden",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Disclosure;
