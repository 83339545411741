import { FC } from "react";
import classNames from "classnames";
import ReactPaginate from "react-paginate";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/20/solid";

const defaultMessage = (begin: number, end: number, total: number) => {
  return (
    <p className="text-sm text-gray-700">
      Mostrando resultados <span className="font-medium">{begin}</span> a{" "}
      <span className="font-medium">{end}</span> de
      <span className="font-medium"> {total}</span> resultados.
    </p>
  );
};

interface PaginationFooterProps {
  rowCounts: number;
  itemsOffSet: number;
  totalItems: number;
  totalPages: number;
  handlePageClick: (selectedItem: { selected: number }) => void;
  message?: (begin: number, end: number, total: number) => JSX.Element;
}

const PaginationFooter: FC<PaginationFooterProps> = ({
  rowCounts,
  itemsOffSet,
  totalItems,
  totalPages,
  handlePageClick,
  message = defaultMessage,
}) => {
  const untilOffSet = itemsOffSet + rowCounts;

  return (
    <div
      className={classNames(
        "flex flex-wrap gap-4 items-center justify-center mt-1 px-4 py-3 sm:px-6",
        totalPages <= 1 && "hidden"
      )}
    >
      <div className="flex items-center justify-center">
        {message(
          itemsOffSet + 1,
          untilOffSet > totalItems ? totalItems : untilOffSet,
          totalItems
        )}
      </div>
      <div className="flex items-center justify-center">
        <ReactPaginate
          breakLabel={
            <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
              ...
            </span>
          }
          nextLabel={
            <>
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </>
          }
          nextClassName={
            "relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          }
          onPageChange={handlePageClick}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          previousLabel={
            <>
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </>
          }
          previousClassName={
            "relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          }
          renderOnZeroPageCount={null}
          containerClassName={"flex items-center px-4 py-3 sm:px-6"}
          pageLinkClassName={
            "relative inline-flex items-center px-4 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0"
          }
          pageClassName={"isolate inline-flex -space-x-px rounded-md shadow-sm"}
          activeClassName={
            "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
          }
        />
      </div>
    </div>
  );
};

export default PaginationFooter;
