import api from "./api";
import { Nullable } from "../interfaces/GenericTypes";
import {
  BCconsultInterface,
  BCsendB2PInterface,
  Banco,
  OutC2P,
} from "../interfaces";
import { bancaribeFormat } from "../utils";

const baseURL = `${process.env.REACT_APP_API_HOST}/Bancaribe`;

// Bank Timestamp
let bankTimestamp = 0;

// Bank lists
let bankB2P: Banco[]; // Keeps a local copy of B2P banks
let bankC2P: Banco[]; // Keeps a local copy of C2P banks

// Send B2P transactions Bancaribe
export const sendB2PBC = async (
  bank: string, // ej - "0114", code for Bancaribe
  ci: string, // Needs type V, E or P, ej V12345678
  amount: number, // Transaction amount accepts 2 decimals
  phone: string, // Customer phone, ej 04141234567
  concept: string = "Pago B2P", // Transaction's number guide
  invoice: string = "Factura" // Optional - Business preference //token de sesion de usuario
): Promise<Nullable<BCsendB2PInterface>> => {
  const amountString = bancaribeFormat(amount);
  try {
    const response = await api.post(
      `${baseURL}/B2P?bank=${bank}&ci=${ci}&amount=${amountString}&phone=${phone}&concept=${concept}invoice=${invoice}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

// Send and cancel C2P transactions Bancaribe
export const sendC2PBC = async (
  bank: string, // ej - "0114", code for Bancaribe
  ci: string, // Needs type V, E or P, ej V12345678
  amount: number, // Transaction amount accepts 2 decimals
  phone: string, // Customer phone, ej 04141234567
  otp: string = "", // OTP code - Customer confirmation code
  ref: string = "", // Reference number only needed to cancel transaction
  concept: string = "", // Transaction's number guide
  cancel: boolean = false // Cancel transaction //token de sesion de usuario
): Promise<Nullable<OutC2P>> => {
  const amountString = bancaribeFormat(amount);
  try {
    const response = await api.post(
      `${baseURL}/C2P?bank=${bank}&ci=${ci}&amount=${amountString}&phone=${phone}&concept=${concept}&otp=${otp}&reference=${ref}&cancel=${cancel}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

// Consult B2P, P2C, C2P and TRF Bancaribe
export const consultBC = async (
  ci: string, // Needs type V, E or P, ej V12345678
  amount: number, // Transaction amount accepts 2 decimals
  phone: string, // Customer's phone if P2C or C2P, Business phone if B2P, empty if TRF
  ref: string, // Transaction reference
  trx: string = "PM", // ej - PM: for B2P, P2C or C2P. TRF: Transference
  invoice: string = "" // Optional - Business preference //token de sesion de usuario
): Promise<Nullable<BCconsultInterface>> => {
  const amountString = bancaribeFormat(amount);
  try {
    const response = await api.post(
      `${baseURL}/Consult?ci=${ci}&amount=${amountString}&phone=${phone}&reference=${ref}&trx=${trx}&invoice=${invoice}`
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

// Get and list banks for B2P and C2P Bancaribe
const getBankBC = async (
  type: string // b2p or c2p //token de sesion de usuario
): Promise<Banco[]> => {
  try {
    const response = await api.get(`${baseURL}/Banks?type=${type}`);
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return [];
};

export const listBankBC = async (type: string, token?: string) => {
  if (
    Date.now() > bankTimestamp ||
    bankB2P.length === 0 ||
    bankC2P.length === 0
  ) {
    bankB2P = await getBankBC("b2p");
    bankC2P = await getBankBC("c2p");
    bankTimestamp = Date.now() + 3600 * 1000 * 24;
  }

  return type === "b2p" ? bankB2P : bankC2P;
};
