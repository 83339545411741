import { FC, useMemo, useState } from "react";
import moment from "moment";
import MenuTab from "../MenuTab";
import classNames from "classnames";
import { PrimaryButton } from "../Buttons";
import { formatAmount } from "../../utils";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { DailyStoreOperationTable } from "./DailyStoreOperationTable";
import {
  DailyOperationStatus,
  DailyStoreOperationDTO,
} from "../../interfaces/Dtos/DailyStoreOperationDTO";

const TABS = [
  {
    name: "Aprobadas",
    showCount: false,
  },
  {
    name: "No Aprobadas",
    showCount: false,
  },
];

interface DailyStoreOperationTableProps {
  balance?: number;
  isDollar?: boolean;
  operations: DailyStoreOperationDTO[];
  onDeposit: () => void;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}
export const DailyStoreOperationsCard: FC<DailyStoreOperationTableProps> = ({
  balance = 0,
  isDollar = false,
  operations,
  onDeposit,
  setRefresh,
}) => {
  const [tab, setTab] = useState(0);

  const approvedOperations = useMemo(() => {
    const filteredOperations = operations.filter(
      (op) =>
        op.operationStatusID === DailyOperationStatus.APPROVED ||
        op.registerDate < "2024-03-06"
    );

    const lastWeekOperations = filteredOperations.filter(
      (op) =>
        op.registerDate >= moment().subtract(7, "days").format("YYYY-MM-DD")
    );

    return lastWeekOperations.length > 10
      ? lastWeekOperations
      : filteredOperations.slice(0, 10);
  }, [operations]);

  const notApprovedOperations = useMemo(() => {
    const filteredOperations = operations.filter(
      (op) =>
        op.operationStatusID !== DailyOperationStatus.APPROVED &&
        op.registerDate >= "2024-03-06"
    );

    const oldestIndex = Math.max(
      10,
      filteredOperations.findIndex(
        (op) => op.operationStatusID === DailyOperationStatus.PENDING
      )
    );

    return filteredOperations.slice(0, oldestIndex);
  }, [operations]);

  return (
    <div className="flex flex-col items-center bg-white rounded-xl shadow-md hover:shadow-lg border px-8 pb-6 pt-4">
      <div className="flex gap-2 items-center">
        <span className="text-center">
          <CurrencyDollarIcon className="inline align-middle self-center h-6 w-6 text-indigo-600 mr-1" />

          <span className="text-sm font-semibold text-center text-gray-700">
            Efectivo por Depositar ({isDollar ? "USD" : "VES"})
          </span>
        </span>
      </div>

      <div className="flex flex-col items-center mb-4 text-gray-900 text-[4rem] leading-[4rem]">
        {formatAmount(balance)}
      </div>

      <PrimaryButton
        onClick={onDeposit}
        className="mt-3 w-full md:w-64 xl:w-full max-w-[20rem]"
      >
        Registrar Depósito
      </PrimaryButton>

      <div className="w-full mt-8">
        <div className="mx-2">
          <MenuTab current={tab} onTabClick={setTab} tabs={TABS} />
        </div>

        <div
          className={classNames(
            "overflow-y-auto w-full max-h-[15rem]",
            tab === 1 && "hidden"
          )}
        >
          <DailyStoreOperationTable
            isDollar={isDollar}
            setRefresh={setRefresh}
            operations={approvedOperations}
          />
        </div>

        <div
          className={classNames(
            "overflow-y-auto w-full max-h-[15rem]",
            tab === 0 && "hidden"
          )}
        >
          <DailyStoreOperationTable
            isDollar={isDollar}
            setRefresh={setRefresh}
            operations={notApprovedOperations}
          />
        </div>
      </div>
    </div>
  );
};
