import { FC, useEffect } from "react";
import { loaderService } from "../services";

const GlobalLoader: FC = () => {
  const isLoading = loaderService.useIsLoading();

  useEffect(() => {
    if (isLoading) {
      document.body.classList.add("loading");
    } else {
      document.body.classList.remove("loading");
    }
  }, [isLoading]);

  return <></>;
};

export default GlobalLoader;
