import { FC, useMemo, useState } from "react";
import classNames from "classnames";
import { PDocumentInterface } from "../../interfaces";
import { formatAmount } from "../../utils";
import AnimatedHeight from "../AnimatedHeight";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

interface PDocumentDetailModalProps {
  pdocument: PDocumentInterface;
}
export const PDocumentDetailModal: FC<PDocumentDetailModalProps> = ({
  pdocument,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const paid = useMemo(() => {
    return pdocument.payments.reduce(
      (acc, p) =>
        acc + p.paymentDetails.reduce((acc, pd) => acc + pd.amountBaseCurr, 0),
      0
    );
  }, [pdocument.payments]);

  return (
    <div className="flex flex-col max-w-full flex-1">
      <div className="flex w-full justify-between mr-8 gap-4">
        <h2 className="text-2xl font-semibold text-gray-900 truncate">
          {pdocument.documentNumber ? "Factura" : "Reporte"}{" "}
          <span className="text-indigo-600 font-bold">
            {pdocument.documentNumber ?? pdocument.reportID}
          </span>
        </h2>
      </div>

      {/* Header */}
      <div className="flex w-full flex-col mt-6 leading-tight font-semibold">
        <p>
          Nombre / Razón Social:{" "}
          <span className="font-normal">
            Transferencias y Encomiendas Angulo López, C.A.
          </span>
        </p>

        <p>
          Dirección:{" "}
          <span className="font-normal text-sm">
            Esq al Este del Puente Soublette, Acceso a la Av. Sur 2 Bis Bis con
            calle 100 Local Galpón Nro. S/N Urb. Quinta Crespo Caracas Distrito
            Capital
          </span>
        </p>

        <p>
          Cédula / RIF: <span className="font-normal">J-00220608-0</span>
        </p>
      </div>

      <div
        className={classNames(
          "overflow-x-auto",
          pdocument.items.length === 0 && "hidden"
        )}
      >
        <table className="table-auto w-full mt-4 text-sm">
          <thead>
            <tr className="h-12 font-bold">
              <th className="text-left py-2">CÓDIGO</th>

              <th className="text-left px-4 py-2">CONCEPTO</th>

              <th className="text-center px-4 py-2 truncate">CANTIDAD</th>

              <th className="text-right py-2">MONTO EN BS.</th>
            </tr>
          </thead>
          <tbody>
            {pdocument.items.map((item, index) => {
              return (
                <tr key={index}>
                  <td className="py-1 text-gray-800 font-semibold truncate">
                    {item.itemID}
                  </td>

                  <td className="px-4 py-1 text-gray-800 font-semibold">
                    {item.itemName}
                  </td>

                  <td className="px-4 py-1 text-center text-gray-800 font-semibold">
                    {item.itemQuantity}
                  </td>

                  <td className="py-1 text-right text-gray-800 font-semibold truncate">
                    {formatAmount(item.grossAmountBaseCurr)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <hr
          className={classNames(
            "border border-gray-500 my-10",
            pdocument.payments.length === 0 && "hidden"
          )}
        />

        <div className="flex w-full items-end justify-end">
          <div className="flex flex-col font-medium">
            <div className="flex justify-between gap-20">
              <p>Subtotal Exento Bs.</p>
              <p>
                {formatAmount(
                  pdocument.items
                    .filter((i) => !i.pctTax)
                    .reduce((acc, i) => acc + i.grossAmountBaseCurr, 0)
                )}
              </p>
            </div>

            <div className="flex justify-between gap-20">
              <p>Subtotal Gravable Bs.</p>
              <p>
                {formatAmount(
                  pdocument.items
                    .filter((i) => i.pctTax)
                    .reduce((acc, i) => acc + i.grossAmountBaseCurr, 0)
                )}
              </p>
            </div>

            <div className="flex justify-between gap-20">
              <p>IVA (16.0%) Bs.</p>
              <p>
                {formatAmount(
                  pdocument.items
                    .filter((i) => i.pctTax)
                    .reduce((acc, i) => acc + i.taxAmountBaseCurr, 0)
                )}
              </p>
            </div>

            <div className="flex justify-between gap-20">
              <p>Total Factura sin IGTF Bs.</p>
              <p>
                {formatAmount(
                  pdocument.items.reduce(
                    (acc, i) => acc + i.totalAmountBaseCurr,
                    0
                  )
                )}
              </p>
            </div>

            <div className="flex justify-between gap-20">
              <p>IGTF (0.0%) Bs.</p>
              <p>{formatAmount(0)}</p>
            </div>

            <div className="flex justify-between gap-20">
              <p className="font-bold">Total Factura Bs.</p>
              <p>
                {formatAmount(
                  pdocument.items.reduce(
                    (acc, i) => acc + i.totalAmountBaseCurr,
                    0
                  )
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr className="border border-gray-500 mt-10 mb-4" />

      <div className="flex items-center">
        <p className="text-gray-800 font-semibold text-2xl">Pagos</p>
        <div
          className="p-2 ml-4 hover:bg-gray-200 border cursor-pointer rounded-full"
          onClick={() => setShowDetails(!showDetails)}
        >
          <ChevronDownIcon
            style={{
              transform: `rotate(${!showDetails ? 0 : 180}deg)`,
              transitionDuration: "1000ms",
            }}
            className="h-8 w-8 flex-none text-gray-400 transition-all ease-in-out"
            aria-hidden="true"
          />
        </div>
      </div>

      <AnimatedHeight
        duration={1000}
        active={showDetails}
        className="flex flex-col px-8 gap-6 mb-6"
      >
        <table
          className={classNames(
            "table-auto w-full mt-4 text-sm",
            pdocument.payments.length === 0 && "hidden"
          )}
        >
          <thead>
            <tr className="h-12 font-bold">
              <th className="text-left py-2">MÉTODO DE PAGO</th>

              <th className="text-left px-4 py-2">COMPROBANTE</th>

              <th className="text-right py-2">MONTO EN BS.</th>
            </tr>
          </thead>
          <tbody>
            {pdocument.payments.map((payment) => {
              return payment.paymentDetails.map((paymentDetail) => {
                return (
                  <tr
                    key={`${payment.paymentID}-${paymentDetail.paymentDetailID}`}
                  >
                    <td className="py-1 text-gray-800 font-semibold truncate">
                      {paymentDetail.paymentMethodName}{" "}
                      <span
                        className={classNames(
                          paymentDetail.paymentMethodID !== 6 && "hidden"
                        )}
                      >
                        ({+paymentDetail.currencyID === 1 ? "VES" : "USD"})
                      </span>
                    </td>

                    <td className="px-4 py-1 text-gray-800 font-semibold">
                      {paymentDetail.attachmentID}
                    </td>

                    <td className="py-1 text-right text-gray-800 font-semibold truncate">
                      {formatAmount(paymentDetail.amountBaseCurr)}
                    </td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>

        <hr className="my-4" />

        <div className="flex w-full items-end justify-end">
          <div>
            <div className="flex justify-between gap-20 mt-4">
              <p>Total Pagado Bs.</p>
              <p>{formatAmount(paid)}</p>
            </div>
            <div className="flex justify-between gap-20">
              <p className="font-bold">Total por Pagar Bs.</p>
              <p>{formatAmount(pdocument.balanceAmountBaseCurr)}</p>
            </div>
          </div>
        </div>
      </AnimatedHeight>

      <div
        className={classNames(
          "flex flex-1 items-center justify-center min-h-[5rem]",
          pdocument.items.length > 0 && "hidden"
        )}
      >
        <p className="text-gray-400">No hay items en esta factura</p>
      </div>
    </div>
  );
};
