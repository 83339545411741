import api from "./api";
import { Nullable, ResponseInterface } from "../interfaces";
import { GeneralAppDataDto } from "../interfaces/Dtos";

const baseURL = process.env.REACT_APP_API_HOST;

export const getBaseAppData = async (
  countryID: Nullable<number>
): Promise<Promise<ResponseInterface<GeneralAppDataDto>>> => {
  let uri = `${baseURL}/Application/Facturador`;
  if (!!countryID) {
    uri += `?countryID=${countryID}`;
  }
  try {
    const response = await api.get(uri);

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: `Server status: ${response.status}`,
      errorMessage: "Server error",
    };
  } catch (error) {
    return {
      model: null,
      didError: true,
      message: `Error: ${error}`,
      errorMessage: "Application error 4",
    };
  }
};
