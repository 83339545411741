import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PaymentMode,
  DeliveryType,
  ShipmentStatus,
  ShipmentService,
  ShipmentInterface,
  BusinessUnitInterface,
} from "../../interfaces";

interface PriceCalculatorState {
  shipmentDraft: Partial<ShipmentInterface>;
}

const initialState: PriceCalculatorState = {
  shipmentDraft: {
    pieces: [],
    items: [],
    deliveryType: DeliveryType.AT_OFFICE,
    paymentMode: PaymentMode.COD,
    service: ShipmentService.STANDARD,
    status: ShipmentStatus.DRAFT,
  },
};

export const priceCalculatorSlice = createSlice({
  name: "priceCalculator",
  initialState,
  reducers: {
    setPriceCalculatorDraft: (
      state,
      action: PayloadAction<Partial<BusinessUnitInterface>>
    ) => {
      state.shipmentDraft = {
        ...state.shipmentDraft,
        ...action.payload,
      };
    },
    clearPriceCalculator: (state) => {
      state.shipmentDraft = initialState.shipmentDraft;
    },
  },
});

export const { clearPriceCalculator, setPriceCalculatorDraft } =
  priceCalculatorSlice.actions;

export default priceCalculatorSlice.reducer;
