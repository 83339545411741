import { FC, useEffect, useMemo, useState } from "react";
import Modal from "../Modal";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import PaymentList from "../Payment/PaymentList";
import PaymentTotal from "../Payment/PaymentTotal";
import ShipmentTable from "../Shipment/ShipmentTable";
import { ClientDetails } from "../Shipment/ShipmentHeader";
import { UserInterface } from "../../interfaces/UserInterface";
import { FiscalSustitutionModal } from "./FiscalSustitutionModal";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import { documentStatusFormat } from "../../utils";
import {
  CurrencyDollarIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Banco,
  DocumentStatus,
  PaymentInterface,
  DocumentInterface,
  DocumentType,
  PaymentMethodEnum,
  PaymentStatusEnum,
  AccountInterface,
  BankAccountInterface,
  PaymentMode,
} from "../../interfaces";
import {
  sendB2PBC,
  listBankBC,
  saveProofOfPayment,
  alertService,
  updatePayment,
  getDocument,
  loaderService,
  updateDocumentStatus,
  annulationMerchant,
  getVtid,
  getAccount,
  savePayment,
  mercantilChange,
  ReintegrationRequest,
  deleteFileDO,
} from "../../services";
import {
  setAppRefreshDocuments,
  setShipmentCreateDocument,
} from "../../store/slices";
import LoadingIcon from "../LodingIcon";
import { BUCreditDTO } from "../../interfaces/Dtos/BUCreditDTO";

interface DocumentDetailsModalProps {
  document?: DocumentInterface;
  openModal: boolean;
  buCredit?: BUCreditDTO;
  onPay: () => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDocument?: (document: DocumentInterface) => void;
}

const DocumentDetailsModal: FC<DocumentDetailsModalProps> = ({
  document,
  openModal,
  buCredit,
  onPay,
  setOpenModal,
  setSelectedDocument = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = loaderService.useIsLoading();
  const user = useAppSelector((state) => state.user.user);
  const userBU = useAppSelector((state) => state.user.businessUnit);
  const createShipmentDocument = useAppSelector(
    (state) => state.shipmentCreate.document
  );

  const taxes = useAppSelector((state) => state.shipmentCreate.taxes);
  const shipments = useAppSelector((state) => state.shipmentCreate.shipments);

  const [vtid, setVtid] = useState<string>();
  const [b2pBank, setB2pBank] = useState<Banco[]>();
  const [accountBillTo, setAccountBillTo] = useState<AccountInterface>();
  const [openSustitutionModal, setOpenSustitutionModal] = useState(false);
  const businessUnit = useAppSelector((state) => state.user.businessUnit);
  const [documentApplied, setDocumentApplied] = useState<DocumentInterface>();

  const subTotal = useMemo(() => {
    return +shipments
      .reduce((acc, shipment) => {
        return acc + (shipment?.total ?? 0);
      }, 0)
      .toFixed(2);
  }, [shipments]);

  const daysSinceCreation = useMemo(() => {
    if (!document?.creationDate) return 0;

    const creationDate = new Date(document.creationDate);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - creationDate.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }, [document?.creationDate]);

  const defeated = useMemo(() => {
    return (
      !!buCredit &&
      (document?.paymentMode === PaymentMode.BOXOFFICE_CREDIT ||
        document?.paymentMode === PaymentMode.DEST_BOXOFFICE_CREDIT) &&
      buCredit.creditDays < daysSinceCreation
    );
  }, [buCredit, daysSinceCreation]);

  const total = useMemo(() => {
    return +(
      subTotal +
      taxes?.reduce((acc, tax) => {
        if (tax.type === "percentage") {
          return acc + subTotal * (tax.value / 100);
        }
        return acc + tax.value;
      }, 0)
    ).toFixed(2);
  }, [subTotal, taxes]);

  const pendingTotal = useMemo(() => {
    return (
      document?.payments
        .filter((p) => p.status === PaymentStatusEnum.PENDING)
        .reduce((acc, payment) => acc + payment.amount, 0) ?? 0
    );
  }, [document?.payments]);

  const amountReceivable = useMemo(() => {
    if (!document) {
      return 0;
    }
    return (
      document.payments
        .filter(
          (payment) =>
            payment.status === PaymentStatusEnum.APPROVE ||
            payment.status === PaymentStatusEnum.PENDING
        )
        .reduce((total, payment) => {
          return payment.amount * 100 + total;
        }, 0) / 100
    );
  }, [document]);

  const hasPermissions = useMemo(() => {
    return (
      (!!document &&
        user?.businessUnitList.some(
          (bu) => bu.code === document.buCodeInvoicer
        )) ||
      user?.roleName === "Superadministrador"
    );
  }, [document, user?.businessUnitList, user?.roleName]);

  const onSaveProofOfPayment = async (
    file: File,
    retention: PaymentInterface,
    user: UserInterface,
    statusID: number,
    reference?: string,
    isModification?: boolean
  ) => {
    if (!document) return;

    const saved = await saveProofOfPayment(
      file,
      retention,
      user,
      statusID,
      reference,
      isModification,
      document?.documentID
    );

    if (!saved || !!saved.didError) {
      alertService.error("Error al guardar el comprobante de pago");
      return;
    }
    alertService.success("El comprobante se guardó exitosamente");

    const updatedDocument = (await getDocument(document.documentID))!;

    setSelectedDocument(updatedDocument);

    if (createShipmentDocument?.documentID === document.documentID) {
      dispatch(setShipmentCreateDocument(updatedDocument));
    }
    dispatch(setAppRefreshDocuments(true));
  };

  const onDeleteProofOfPayment = async (retention: PaymentInterface) => {
    loaderService.start();

    const response = await deleteFileDO(retention.proofOfPayment!);

    if (response.didError || !response.model) {
      loaderService.stop();
      alertService.error(
        "Error al eliminar el comprobante de pago",
        response.errorMessage
      );
      return;
    }

    alertService.success("El comprobante se eliminó exitosamente");

    const update = await updatePayment({
      documentID: document?.documentID ?? retention.documentID!,
      paymentDetailID: retention.paymentDetailID!,
      statusID: PaymentStatusEnum.PENDING,
      user: user!,
    });

    if (update.didError) {
      alertService.error(
        "Error al eliminar el comprobante",
        update.errorMessage
      );
      loaderService.stop();
      return;
    }

    const updatedDocument: DocumentInterface = {
      ...document!,
    };

    loaderService.stop();
    setSelectedDocument(updatedDocument);
    dispatch(
      setShipmentCreateDocument({
        ...updatedDocument,
      })
    );
    dispatch(setAppRefreshDocuments(true));
  };

  const onSavePayment = async (
    payment: PaymentInterface,
    savedDocument: DocumentInterface,
    currencyId: number
  ) => {
    loaderService.start();
    const response = await savePayment(
      payment,
      savedDocument!,
      userBU?.code,
      user ?? undefined,
      currencyId
    );
    if (response.didError || !response.model) {
      loaderService.stop();
      alertService.error("Error al guardar el pago", response.errorMessage);
      return {
        error: true,
        message: "Error al guardar el pago",
      };
    }

    const documentResponse = await getDocument(savedDocument!.documentID);
    if (!documentResponse) {
      loaderService.stop();
      return {
        error: true,
        message: "Error al obtener la orden",
      };
    }

    const totalPaid = documentResponse.payments
      .filter(
        (p) =>
          p.status !== PaymentStatusEnum.CANCELED &&
          p.status !== PaymentStatusEnum.REJECT
      )
      .reduce((acc, payment) => acc + payment.amount, 0);
    const newTotal = total + payment.igtfAmount;
    const remaining = newTotal - totalPaid;
    const status =
      (documentResponse.balanceAmount ?? remaining) < 0.01
        ? DocumentStatus.PAID
        : DocumentStatus.PARTIAL_PAID;

    let updatedDocument = {
      ...savedDocument!,
      payments: documentResponse.payments,
      total: documentResponse.total ?? newTotal,
      status,
      igtfAmount: savedDocument.igtfAmount + payment.igtfAmount,
      balanceAmount: documentResponse.balanceAmount,
      baseBalanceAmount: documentResponse.baseBalanceAmount,
    };

    setSelectedDocument({
      ...updatedDocument,
    });
    dispatch(setAppRefreshDocuments(true));

    if (createShipmentDocument?.documentID === updatedDocument.documentID) {
      dispatch(
        setShipmentCreateDocument({
          ...updatedDocument,
        })
      );
    }

    loaderService.stop();

    return {
      error: false,
      message: "",
    };
  };

  const payChange = async (payment: PaymentInterface) => {
    if (!document || !user) {
      return {
        error: true,
        message: "Error al obtener la orden",
      };
    }
    let result = {
      error: false,
      message: "",
    };

    loaderService.start();
    let savedDocument = document;

    if (user?.roleName === "Superadministrador") {
      payment.paymentMethod = {
        ...payment.paymentMethod,
        paymentMethodID: Math.abs(payment.paymentMethod.paymentMethodID),
      };
    }

    // B2P change payment - Bancaribe
    if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO &&
      payment.destBankID === 6
    ) {
      const res = await sendB2PBC(
        payment.bank!,
        payment.clientIdentifier!,
        payment.amount,
        payment.phone!,
        document ? `Asociado a orden #${document.documentID}` : "Pago 1 B2P"
      );

      if (res?.codigoError !== 0) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res?.descripcionError ?? "Error al procesar el pago",
        };
      } else {
        payment.reference = res?.codigoConfirmacion;
      }
    }

    // B2P change payment - Mercantil
    if (
      payment.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO &&
      payment.destBankID === 4
    ) {
      const res = await mercantilChange(
        payment.phone!,
        payment.clientIdentifier!,
        document?.documentID!.split("-")[0]!,
        payment.amount,
        payment.bank!
      );
      if (res?.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        let errorMessage = "";
        if (res.model?.error_list && res.model?.error_list.length > 0) {
          errorMessage = res.model?.error_list
            .map((error) => error.description)
            .join(", ");
        }
        result = {
          error: true,
          message: errorMessage || "Error al procesar el pago",
        };
      } else {
        payment.reference = `${res?.model?.transaction_c2p_response?.payment_reference}:${res?.model?.transaction_c2p_response?.invoice_number}`;
      }
    }

    // Reintegration
    if (
      payment.paymentMethod.paymentMethodID === PaymentMethodEnum.REINTEGRO &&
      payment.status === PaymentStatusEnum.PENDING
    ) {
      const res = await ReintegrationRequest(
        user?.userLogin,
        businessUnit!,
        document,
        payment.amount,
        document.accountOwner!
      );

      payment.paymentMethod.bankAccountID = undefined;

      if (res.didError) {
        payment.status = PaymentStatusEnum.REJECT;
        result = {
          error: true,
          message: res.errorMessage,
        };
      } else {
        payment.status = PaymentStatusEnum.PENDING;
      }
    }

    // Convert amount to negative if change
    if (
      [
        PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO,
        PaymentMethodEnum.VUELTO_EFECTIVO,
        PaymentMethodEnum.REINTEGRO,
      ].includes(payment.paymentMethod.paymentMethodID)
    ) {
      payment.amount = -payment.amount;
      payment.paymentAmount = -(payment.paymentAmount ?? 0);
    }

    // Change currency
    let currencyId = payment.paymentMethod.currencyID;

    loaderService.stop();
    if (result.error) {
      const response = {
        ...result,
        payment,
        savedDocument,
        currencyId,
      };

      return response;
    }

    return onSavePayment(payment, savedDocument, currencyId);
  };

  const onCancelRetention = async (retention: PaymentInterface) => {
    if (!document || !user) return;

    loaderService.start();
    const response = await updatePayment({
      documentID: document?.documentID ?? retention.documentID!,
      paymentDetailID: retention.paymentDetailID!,
      statusID: PaymentStatusEnum.CANCELED,
      user: user!,
    });

    if (!!response.didError) {
      alertService.error("Error al cancelar la retención");
      loaderService.stop();
      return;
    }

    const documentResponse = await getDocument(document!.documentID);
    if (!documentResponse) {
      alertService.error("Error al obtener la orden");
      loaderService.stop();
      return;
    }

    const totalPaid = documentResponse.payments
      .filter(
        (p) =>
          p.status !== PaymentStatusEnum.CANCELED &&
          p.status !== PaymentStatusEnum.REJECT
      )
      .reduce((acc, payment) => acc + payment.amount, 0);
    const remaining = document.total - totalPaid;
    const status =
      (documentResponse.balanceAmount ?? remaining) < 0.01
        ? DocumentStatus.PAID
        : DocumentStatus.PARTIAL_PAID;

    let updatedDocument = {
      ...document!,
      payments: documentResponse.payments,
      total: documentResponse.total ?? document.total,
      status,
      balanceAmount: documentResponse.balanceAmount,
      baseBalanceAmount: documentResponse.baseBalanceAmount,
    };

    // Update document status
    if (document?.status !== status) {
      const response = await updateDocumentStatus(
        updatedDocument.documentID,
        status,
        undefined,
        user ?? undefined,
        status === DocumentStatus.PAID ? userBU?.code : undefined, // set BU invoicer,
        updatedDocument.documentType
      );
      if (!!response.didError) {
        alertService.error("Hubo un error actualizando el estado de la orden");
      } else {
        updatedDocument.status = status;
      }
    }

    loaderService.stop();
    setSelectedDocument(updatedDocument);
  };

  const onCancelPayment = async (transaction: PaymentInterface) => {
    if (!document || !user) return;

    loaderService.start();
    const currentVtid = vtid ?? (await saveVtid());

    if (currentVtid === null) {
      alertService.error("No se pudo obtener el VTID");
      loaderService.stop();
      return;
    }

    if (transaction.reference) {
      const paymentVtid = transaction.reference.split(":")[1];

      if (paymentVtid === currentVtid) {
        const numSeq = transaction.reference.split(":")[0];
        const res = await annulationMerchant(numSeq);

        if (res !== null && res.codRespuesta === "00") {
          const response = await updatePayment({
            documentID: document?.documentID ?? transaction.documentID!,
            paymentDetailID: transaction.paymentDetailID!,
            statusID: PaymentStatusEnum.CANCELED,
            user: user!,
          });

          if (response.didError) {
            alertService.error("Error al cancelar el pago");
            loaderService.stop();
            return;
          }
          alertService.success("El pago se anuló exitosamente");

          const documentResponse = await getDocument(document!.documentID);
          if (!documentResponse) {
            alertService.error("Error al obtener la orden");
            loaderService.stop();
            return;
          }

          const totalPaid = documentResponse.payments
            .filter(
              (p) =>
                p.status !== PaymentStatusEnum.CANCELED &&
                p.status !== PaymentStatusEnum.REJECT
            )
            .reduce((acc, payment) => acc + payment.amount, 0);
          const remaining = document.total - totalPaid;
          const status =
            (documentResponse.balanceAmount ?? remaining) < 0.01
              ? DocumentStatus.PAID
              : DocumentStatus.PARTIAL_PAID;

          let updatedDocument = {
            ...document!,
            payments: documentResponse.payments,
            total: documentResponse.total ?? document.total,
            status,
            balanceAmount: documentResponse.balanceAmount,
            baseBalanceAmount: documentResponse.baseBalanceAmount,
          };

          // Update document status
          if (document?.status !== status) {
            const response = await updateDocumentStatus(
              updatedDocument.documentID,
              status,
              undefined,
              user ?? undefined,
              status === DocumentStatus.PAID ? userBU?.code : undefined, // set BU invoicer,
              updatedDocument.documentType
            );
            if (!!response.didError) {
              alertService.error(
                "Hubo un error actualizando el estado de la orden"
              );
            } else {
              updatedDocument.status = status;
            }
          }

          loaderService.stop();
          setSelectedDocument(updatedDocument);
          return;
        }
        alertService.error("No se pudo anular el pago");
        loaderService.stop();
        return;
      }
      alertService.error("Este pago no se proceso con el terminal actual");
      loaderService.stop();
      return;
    }
    alertService.error(
      "La operación no tiene terminal asociado, no se puede anular"
    );
    loaderService.stop();
  };

  const onApproveReintegration = async (
    transaction: PaymentInterface,
    bankAccount?: BankAccountInterface
  ) => {
    loaderService.start();
    const response = await updatePayment({
      documentID: document!.documentID,
      paymentDetailID: transaction.paymentDetailID!,
      bankAccountID: bankAccount?.bankAccountID,
      sourceBankID: bankAccount?.bankID,
      statusID: PaymentStatusEnum.APPROVE,
      user: user!,
    });

    if (response.didError) {
      alertService.error("No se pudo aprobar el reintegro");
      loaderService.stop();
      return;
    }
    alertService.success("Reintegro aprobado con éxito");
    const documentResponse = await getDocument(document!.documentID);
    if (!documentResponse) {
      alertService.error("Error al obtener la orden");
      loaderService.stop();
      return;
    }

    const totalPaid = documentResponse.payments
      .filter(
        (p) =>
          p.status !== PaymentStatusEnum.CANCELED &&
          p.status !== PaymentStatusEnum.REJECT
      )
      .reduce((acc, payment) => acc + payment.amount, 0);
    const remaining = total - totalPaid;
    const status =
      (documentResponse.balanceAmount ?? remaining) < 0.01
        ? DocumentStatus.PAID
        : DocumentStatus.PARTIAL_PAID;

    let updatedDocument = {
      ...document!,
      payments: documentResponse.payments,
      total: documentResponse.total ?? total,
      status,
      balanceAmount: documentResponse.balanceAmount,
      baseBalanceAmount: documentResponse.baseBalanceAmount,
    };

    // Update document status
    if (document?.status !== status) {
      const response = await updateDocumentStatus(
        updatedDocument.documentID,
        status,
        undefined,
        user ?? undefined,
        status === DocumentStatus.PAID ? userBU?.code : undefined, // set BU invoicer,
        updatedDocument.documentType
      );
      if (response.didError) {
        alertService.error(
          "Hubo un error actualizando el estado de la orden",
          response.errorMessage
        );
      } else {
        updatedDocument.status = status;
      }
    }

    loaderService.stop();
    setSelectedDocument(updatedDocument);
  };

  const saveVtid = async () => {
    const buCode = businessUnit?.code;
    if (!buCode) return;

    const buConfigResponse = await getVtid(buCode);
    if (buConfigResponse.didError) return;

    const vtid = buConfigResponse.model?.vtid;
    if (!vtid) return;

    setVtid(vtid);
    return vtid;
  };

  useEffect(() => {
    if (!openModal || b2pBank === undefined) return;

    const listBank = async () => {
      const res: Banco[] = await listBankBC("b2p");
      if (res !== null) {
        setB2pBank(res);
      }
    };
    listBank();
  }, [openModal]);

  useEffect(() => {
    if (!document?.accountOwner?.id) return;

    const getAccountBillTo = async () => {
      if (document.accountOwner?.id) {
        loaderService.start();
        const account = await getAccount(document.accountOwner.id);
        loaderService.stop();

        if (account) {
          setAccountBillTo(account);
        }
      }
    };

    getAccountBillTo();
  }, [document?.accountOwner?.id]);

  useEffect(() => {
    if (!document?.documentAppliedID) return;

    const getDocumentApplied = async () => {
      const doc = await getDocument(document.documentAppliedID!);
      if (doc) {
        setDocumentApplied(doc);
      }
    };

    getDocumentApplied();
  }, [document?.documentAppliedID]);

  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      className="bg-gray-200"
    >
      {!document && (
        <div className="flex items-center justify-center w-full p-8">
          <LoadingIcon size="6rem" />
        </div>
      )}

      {!!document && hasPermissions && (
        <>
          <div className="flex flex-1 flex-row justify-between items-center bg-white rounded-lg border px-8 py-6">
            <div>
              <div className="flex flex-col xl:flex-row xl:gap-2">
                <p className="font-bold text-xl">
                  {document.documentType === DocumentType.INVOICE
                    ? "Factura:"
                    : document.documentType === DocumentType.CREDIT_NOTE
                    ? "Nota de Crédito:"
                    : "Orden:"}
                </p>
                <p className="text-indigo-600 font-bold text-xl">
                  {document.documentNumber ?? document.documentID.slice(0, 8)}
                </p>
              </div>

              <LinkText
                text={`Factura afectada ${document.documentAffectedDocumentNumber}`}
                className={classNames(
                  (!document.documentAffectedID ||
                    document.documentType !== DocumentType.CREDIT_NOTE) &&
                    "hidden"
                )}
                onClick={() =>
                  navigate(`/documents/${document.documentAffectedID}`)
                }
              />

              <br
                className={classNames(
                  (!document.documentAffectedID ||
                    document.documentType !== DocumentType.CREDIT_NOTE) &&
                    "hidden"
                )}
              />

              <LinkText
                text={
                  document.documentType === DocumentType.CREDIT_NOTE
                    ? `Utilizada como pago de la Factura ${document.documentAppliedDocumentNumber}`
                    : "Ver Nota de Crédito aplicada"
                }
                className={classNames(!document.documentAppliedID && "hidden")}
                onClick={() =>
                  navigate(`/documents/${document.documentAppliedID}`)
                }
              />

              <div className="flex flex-col md:flex-row md:gap-2 text-sm">
                <p className="text-gray-600">Creación:</p>
                <p className="text-gray-800 font-semibold">
                  {!!document?.creationDate &&
                    new Date(document.creationDate)
                      .toLocaleDateString("es-VE", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                      .replace("a", "AM")
                      .replace("p", "PM")
                      .slice(0, -4)}
                </p>
              </div>
            </div>

            <div className="ml-3 flex h-7 items-center">
              <button
                type="button"
                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={() => setOpenModal(false)}
              >
                <span className="absolute -inset-2.5" />
                <span className="sr-only">Close panel</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>

          <div
            className="mt-5 flex flex-1 flex-col gap-6"
            style={{ width: "80vw" }}
          >
            {/* Show invoice owner */}
            {!!document.accountOwner && (
              <div className="flex flex-1 gap-16 flex-row items-start justify-between bg-white rounded-lg border px-8 py-4">
                <div className="flex flex-1 flex-col">
                  <div className="flex flex-1 flex-row gap-2">
                    <p className="text-sm text-gray-900 font-semibold">
                      FACTURAR A:
                    </p>
                    <p className="text-sm truncate">
                      {document.accountOwner.accountFullName}
                    </p>
                  </div>

                  <div className="flex flex-1 flex-col mt-4">
                    <ClientDetails client={document.accountOwner} />
                  </div>
                </div>

                <div className="flex flex-col items-end">
                  <div className="text-end">
                    <p>Estado:</p>

                    <p className="font-medium text-xl leading-none italic">
                      {document.documentAppliedID &&
                      document.documentType !== DocumentType.CREDIT_NOTE &&
                      documentApplied?.total === document.total
                        ? "Anulada"
                        : defeated
                        ? "Vencida"
                        : documentStatusFormat(document.status)}
                    </p>
                  </div>

                  {!!document.urlDocument && (
                    <LinkText
                      text={
                        document.documentType === DocumentType.INVOICE
                          ? "Ver Factura"
                          : "Ver Nota de Crédito"
                      }
                      onClick={() =>
                        window.open(document.urlDocument!, "_blank")
                      }
                    />
                  )}

                  {!document.urlDocument &&
                    document.documentType === DocumentType.INVOICE && (
                      <p className="text-xs text-gray-400 text-right mt-1">
                        *Factura fiscal digital no disponible.
                      </p>
                    )}

                  {!document.urlDocument &&
                    document.documentType === DocumentType.ORDER && (
                      <p className="text-xs text-gray-400 text-right mt-1">
                        *Esta orden no ha generado factura fiscal.
                      </p>
                    )}
                </div>
              </div>
            )}

            {/* Show shipments resumen */}
            <div className={"pr-1"} style={{ flex: 1 }}>
              <ShipmentTable
                shipments={document.shipments}
                onShipmentClick={(shipment) => {
                  navigate(`/shipments/${shipment.shipmentNumber}`);
                }}
                showDetails={false}
              />
            </div>

            {/* Show totals */}
            <PaymentTotal
              baseRemaining={(document.baseBalanceAmount ?? 0) - pendingTotal}
              paymentMode={document.paymentMode}
              subTotal={document.subTotal}
              total={document.total}
              taxes={document.taxes}
            />

            {/* Show pay methods */}
            <PaymentList
              document={document}
              remaining={document.balanceAmount}
              payments={document.payments}
              owner={document.accountOwner}
              paymentMode={document.paymentMode}
              onCancelRetention={onCancelRetention}
              onCancelPayment={onCancelPayment}
              onSaveProofOfPayment={onSaveProofOfPayment}
              onDeleteProofOfPayment={onDeleteProofOfPayment}
              onPay={payChange}
              onApproveReintegration={onApproveReintegration}
              detailView={true}
            />

            <div className="flex flex-1 justify-between">
              <div>
                {/* {(document.status === DocumentStatus.PARTIAL_PAID ||
              document.status === DocumentStatus.PAID) && (
              <SecondaryButton className="w-40">Anular</SecondaryButton>
            )} */}

                <div
                  className={classNames(
                    (!document.urlDocument ||
                      document.buCodeInvoicer !== userBU?.code) &&
                      "hidden"
                  )}
                >
                  <SecondaryButton
                    className={classNames(
                      "w-40",
                      (document.documentAffectedID ||
                        document.documentAppliedID ||
                        document.buCodeInvoicer !== userBU?.code ||
                        document.payments.some(
                          (p) => p.isRetention && !!p.proofOfPayment
                        )) &&
                        "hidden"
                    )}
                    disabled={loading}
                    onClick={() => setOpenSustitutionModal(true)}
                  >
                    Sustituir Factura
                  </SecondaryButton>
                </div>
              </div>

              <div>
                {/* Change Payment Button */}
                {/* {document.status === DocumentStatus.PAID &&
              document.balanceAmount < 0 && (
                <div className="flex flex-1 justify-end">
                  <PrimaryButton
                    onClick={() => setChangeModal(true)}
                    className="w-40"
                  >
                    <CurrencyDollarIcon
                      className="h-5 w-5 flex-none text-white-400 mr-2"
                      aria-hidden="true"
                    />
                    DAR CAMBIO
                  </PrimaryButton>
                </div>
              )} */}

                {/* Pay Button */}
                {amountReceivable < document.total &&
                  document.buCodeInvoicer === userBU?.code &&
                  document.status !== DocumentStatus.ANULLED && (
                    <PrimaryButton
                      onClick={onPay}
                      className={classNames(
                        "w-40",
                        !!document.documentAppliedID && "hidden"
                      )}
                    >
                      <CurrencyDollarIcon
                        className="h-5 w-5 flex-none text-white-400 mr-2"
                        aria-hidden="true"
                      />
                      PAGAR
                    </PrimaryButton>
                  )}
              </div>
            </div>
          </div>

          {accountBillTo && (
            <FiscalSustitutionModal
              document={document}
              accountBillTo={accountBillTo}
              open={openSustitutionModal}
              setOpen={setOpenSustitutionModal}
            />
          )}
        </>
      )}

      {!!document && !hasPermissions && (
        <div className="flex flex-col items-center justify-center w-full px-8 py-3">
          <div className="flex flex-col items-center justify-center w-full">
            <ExclamationTriangleIcon className="w-32 h-32 text-gray-700" />
          </div>

          <p className="text-gray-600 text-lg">
            No tienes permisos para ver esta{" "}
            {document.documentType === DocumentType.INVOICE
              ? "Factura"
              : document.documentType === DocumentType.CREDIT_NOTE
              ? "Nota de Crédito"
              : "Orden"}
          </p>

          <div className="flex flex-col items-center justify-center w-full mt-4">
            <PrimaryButton className="w-32" onClick={() => setOpenModal(false)}>
              Cerrar
            </PrimaryButton>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default DocumentDetailsModal;
