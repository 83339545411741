import api from "./api";
import {
  BUConfigResponseInterface,
  ClosureResponseInterface,
  ResponseInterface,
} from "../interfaces";

const baseURL = `${process.env.REACT_APP_API_HOST}/CashDesk`;

// Request header
const header = {
  headers: {
    "Content-Type": "application/json",
  },
};

// Insert merchant daily close
export const insertDailyCloseMerchant = async (
  OutputMerchant: string,
  ExecStatusID: number,
  CreationUser: string,
  LastClosureDate: Date | null
): Promise<ResponseInterface<ClosureResponseInterface>> => {
  const date = new Date();
  let response = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };
  const body = {
    DailyCloseDate: date,
    OutputMerchant,
    ExecStatusID,
    CreationUser,
    CreationDate: date,
    LastClosureDate,
  };
  const uri = `${baseURL}/Insert`;

  try {
    const DBresponse = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (
      DBresponse &&
      DBresponse.status === 200 &&
      DBresponse.data.didError === false
    ) {
      return DBresponse.data;
    }
    response.message = "El registro del cierre no fue exitoso";
    return DBresponse.data;
  } catch (error) {}

  response.errorMessage =
    "Hubo un error al registrar el cierre en la base de datos";

  return response;
};

// Get merchant daily close by VTID
export const getDailyCloseMerchant = async (
  vtid: string
): Promise<ResponseInterface<ClosureResponseInterface[]>> => {
  let response = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };
  const uri = `${baseURL}?vtid=${vtid}`;

  try {
    const DBresponse = await api.get(uri);
    if (
      DBresponse &&
      DBresponse.status === 200 &&
      DBresponse.data.didError === false
    ) {
      return DBresponse.data;
    }
    response.message = "El query de los cierres previos no fue exitoso";
    return DBresponse.data;
  } catch (error) {}

  response.errorMessage =
    "Hubo un error al ejecutar el query de cierres en la base de datos";

  return response;
};

// Get VTID by BUCode
export const getVtid = async (
  buCode: string
): Promise<ResponseInterface<BUConfigResponseInterface>> => {
  let res = {
    message: "",
    didError: true,
    errorMessage: "",
    model: null,
  };

  const uri = `${baseURL}/BUConfig?BUCode=${buCode}`;
  try {
    const response = await api.get(uri);

    if (
      response &&
      response.status === 200 &&
      response.data.didError === false
    ) {
      return response.data;
    }
    res.message = "No se encontró el VTID";
    return response.data;
  } catch (error) {}

  res.errorMessage = "Hubo un error al obtener el VTID";
  return res;
};
