import { FC, useEffect, useState } from "react";
import {
  DeliveryType,
  LocationInterface,
  BusinessUnitInterface,
} from "../../interfaces";

interface DestinationDetailsProps {
  location?: LocationInterface;
  businessUnit?: BusinessUnitInterface;
  deliveryType: DeliveryType;
}
const DestinationDetails: FC<DestinationDetailsProps> = ({
  location,
  businessUnit,
  deliveryType,
}) => {
  const [summary, setSummary] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (!!businessUnit && deliveryType === DeliveryType.AT_OFFICE) {
      setSummary(`${businessUnit.code} - ${businessUnit.location.name}`);
      setAddress(
        `${businessUnit.location.address} ${
          !!businessUnit.location.postalCode
            ? `(código postal: ${businessUnit.location.postalCode})`
            : ""
        }`
      );
    } else if (!!location) {
      setSummary(location.name);
      setAddress(
        `${location.address} ${
          !!location.postalCode ? `(código postal: ${location.postalCode})` : ""
        }`
      );
    } else {
      setSummary("");
      setAddress("");
    }
  }, [location, businessUnit, deliveryType]);

  return (
    <div className="px-4 py-5 sm:p-6">
      <div className="flex flex-col gap-4">
        <h2 className="text-2xl font-semibold leading-7 text-gray-900">
          Destino
        </h2>
        {!!location ? (
          <>
            <div className="flex flex-row items-start gap-x-4">
              <div>
                <p className="flex text-sm leading-5">{summary}</p>
                <p className="flex text-xs leading-5">{address}</p>
              </div>
            </div>
          </>
        ) : (
          <p className="text-gray-500 leading-5">
            No se ha seleccionado un destino
          </p>
        )}
      </div>
    </div>
  );
};

export default DestinationDetails;
