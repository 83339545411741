import { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import { FormikProps } from "formik";
import {
  alertService,
  getBankList,
  listBankBC,
  loaderService,
  mercantilC2PKey,
} from "../../services";
import { FormDatePicker, FormSelect, FormTextInput } from "../FormFields";
import { Currency, currencyExchange, useCurrencyExchanges } from "../../utils";
import { AddButton, LinkText, PrimaryButton } from "../Buttons";
import { formatName, formatGooglePhone, cleanID } from "../../utils/format";
import {
  Banco,
  Nullable,
  AccountInterface,
  PaymentMethodEnum,
  PaymentMethodInterface,
  PaymentStatusEnum,
} from "../../interfaces";
import { PaymentInfoModal } from "./PaymentInfoModal";
import { useAppSelector } from "../../store/hooks";

// Identification types for P2C/C2P
const identificationTypes = ["V", "E", "P", "J", "G"];

export enum PaymentDateEnum {
  LAST_4_DAYS = "Últimos 3 días",
  LAST_8_DAYS = "Últimos 7 días",
  SPECIFIC_DATE = "Fecha específica",
}

export interface PaymentFormValues {
  amount: number;
  clientIdentifier: string;
  paymentMethod: PaymentMethodInterface;
  bank: Banco;
  phone: string;
  reference: string;
  otp: string;
  currency: Currency;
  dateOption: PaymentDateEnum;
  date?: string;
  destBankID: Nullable<number>;
  affiliateNumber: string;
  batchNumber: string;
  status: PaymentStatusEnum;
}
interface PaymentFormProps {
  formik: FormikProps<PaymentFormValues>;
  remaining: number;
  payMethods: PaymentMethodInterface[];
  owner?: AccountInterface;
  currentIGTF: number;
  error?: string;
  setErrorCount?: (count: number) => void;
  handlePayAmountChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    formik: FormikProps<any>,
    max: number
  ) => void;
}
const PaymentForm: FC<PaymentFormProps> = ({
  formik,
  remaining,
  payMethods,
  currentIGTF,
  owner,
  error,
  setErrorCount,
  handlePayAmountChange,
}) => {
  const exchanges = useCurrencyExchanges();

  const [idType, setIdType] = useState("V");
  const loading = loaderService.useIsLoading();
  const [p2cModal, setP2cModal] = useState(false);
  const [c2pBank, setC2pBank] = useState<Banco[]>();
  const [currentError, setCurrentError] = useState<string>();
  const [bankList, setBankList] = useState<Banco[]>([]);

  const max = useMemo(() => {
    const realRemaining = Math.max(0, remaining);

    if (
      !(
        formik.values.paymentMethod.paymentMethodID ===
          PaymentMethodEnum.EFECTIVO &&
        formik.values.paymentMethod.currencyID === 2
      )
    ) {
      return realRemaining;
    }

    // The amount to which the IGTF will be applied will be equal to the
    // minimum between the amount to be paid and the rest less the previous
    // calculation
    const amountWithIGTF = Math.max(0, realRemaining - currentIGTF);
    const newIGTF = amountWithIGTF * 0.03;

    return +(realRemaining + newIGTF).toFixed(2);
  }, [remaining, currentIGTF, formik.values.paymentMethod]);

  const filteredPayMethods = useMemo(() => {
    return payMethods.filter(
      (method) =>
        method.paymentMethodID !== PaymentMethodEnum.PAGO_MOVIL_B2P_VUELTO &&
        method.paymentMethodID !== PaymentMethodEnum.VUELTO_EFECTIVO &&
        method.paymentMethodID !== PaymentMethodEnum.REINTEGRO
    );
  }, [payMethods]);

  const showIdInput = useMemo(() => {
    const method = formik.values.paymentMethod.paymentMethodID;
    return (
      method === PaymentMethodEnum.SUPERADMIN_MERCHANT_P2C ||
      method === PaymentMethodEnum.SUPERADMIN_MERCHANT_TARJETA_DE_DEBITO ||
      method === PaymentMethodEnum.MERCHANT ||
      method === PaymentMethodEnum.PAGO_MERCHANT_TARJETA_DE_DEBITO ||
      method === PaymentMethodEnum.PAGO_MERCHANT_TARJETAS_DE_CREDITO ||
      method === PaymentMethodEnum.PAGO_MERCHANT_P2C ||
      method === PaymentMethodEnum.PAGO_MOVIL_P2C ||
      method === PaymentMethodEnum.PAGO_MOVIL_C2P ||
      method === PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA ||
      method === PaymentMethodEnum.TRANSFERENCIA
    );
  }, [formik.values.paymentMethod.paymentMethodID]);

  const showIdTypeInput = useMemo(() => {
    const method = formik.values.paymentMethod.paymentMethodID;
    return (
      method === PaymentMethodEnum.PAGO_MOVIL_P2C ||
      method === PaymentMethodEnum.PAGO_MOVIL_C2P ||
      method === PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA ||
      method === PaymentMethodEnum.TRANSFERENCIA
    );
  }, [formik.values.paymentMethod.paymentMethodID]);

  const showBanksData = useMemo(() => {
    const method = formik.values.paymentMethod.paymentMethodID;
    return (
      method === PaymentMethodEnum.PAGO_MOVIL_P2C ||
      method === PaymentMethodEnum.PAGO_MOVIL_C2P ||
      method === PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA ||
      method === PaymentMethodEnum.TRANSFERENCIA ||
      method === PaymentMethodEnum.PUNTO_DE_VENTA
    );
  }, [formik.values.paymentMethod.paymentMethodID]);

  const showDestBankInput = useMemo(() => {
    const bankAccounts = formik.values.paymentMethod.bankAccounts;
    return bankAccounts && bankAccounts.length >= 2;
  }, [formik.values.paymentMethod.bankAccounts]);

  const showDateInput = useMemo(() => {
    const method = formik.values.paymentMethod.paymentMethodID;
    return (
      method === PaymentMethodEnum.PAGO_MOVIL_P2C ||
      method === PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA
    );
  }, [formik.values.paymentMethod.paymentMethodID]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const cleanClientIdentifier = cleanID(formik.values.clientIdentifier);
    formik.setFieldValue(
      "clientIdentifier",
      `${idType}${cleanClientIdentifier}`
    );
    formik.handleSubmit(e);
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = formatGooglePhone(e.target.value);
    formik.setFieldValue("phone", value);
  };

  const handleC2PKeyRequest = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const res = await mercantilC2PKey(
      formik.values.phone,
      `${idType}${cleanID(formik.values.clientIdentifier)}`
    );
    if (res.didError) {
      alertService.error(
        res.model?.error_list?.[0]?.description ?? res.errorMessage
      );
    } else {
      alertService.success(
        res.model?.transactionScpInfoResponse?.trx_status ??
          "Código C2P solicitado con éxito"
      );
    }
  };

  const payMethodFormat = (method: PaymentMethodInterface) => {
    if (method.paymentMethodID === 6) {
      const suffix = method.currencyID === 1 ? "en Bolívares" : "en Dólares";
      return `${method.paymentMethodName} ${suffix}`;
    } else {
      return `${method.paymentMethodName}`;
    }
  };

  // C2P bank list
  useEffect(() => {
    const listBank = async () => {
      const res: Banco[] = await listBankBC("c2p");
      if (res !== null) {
        setC2pBank(res);
      }
    };

    const getBanks = async () => {
      const res = await getBankList();
      if (res.model !== null && !res.didError) {
        setBankList(res.model);
      }
    };

    listBank();
    getBanks();
  }, []);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      amount: Math.max(
        0,
        +currencyExchange(max, exchanges, formik.values.currency).toFixed(2)
      ),
      destBankID: null,
    });

    // Only the currency, max value and the pay method is required to update
    // the amount, not the entire formik object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [max, formik.values.currency]);

  useEffect(() => {
    formik.setFieldValue(
      "clientIdentifier",
      // slice last char on string
      `${!!owner?.abreviationName ? owner?.abreviationName[0] : ""}${
        owner?.identificationNumber
      }` ?? ""
    );
    formik.setFieldValue(
      "phone",
      owner?.listAccountPhone[0]?.phoneNumber
        ? formatGooglePhone(owner?.listAccountPhone[0]?.phoneNumber)
        : ""
    );

    // Only the owner is required to update the identifier and the phone,
    // not the entire formik object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [owner]);

  useEffect(() => {
    formik.setFieldValue("reference", "");
    setErrorCount && setErrorCount(0);
    setCurrentError("");

    // Only the max value is required to update the amount, not the entire
    // formik object
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.paymentMethod]);

  useEffect(() => {
    setCurrentError(error);
  }, [error]);

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col">
        {/* Main data */}
        <div className="flex flex-row gap-4 w-full items-end pb-2">
          {/* Payment method */}
          <div className="flex flex-col flex-1">
            <FormSelect
              label="Método de pago"
              name="paymentMethod"
              options={filteredPayMethods}
              optionString={payMethodFormat}
              selected={formik.values.paymentMethod}
              onSelectOption={(method) => {
                formik.setValues({
                  ...formik.values,
                  paymentMethod: method,
                  destBankID: null,
                  currency: method.currencyID === 1 ? "BS" : "USD",
                });
              }}
            />
          </div>

          {/* Identification */}
          <div className={classNames("flex flex-1", !showIdInput && "hidden")}>
            <div
              className={classNames(
                !showIdTypeInput && "hidden",
                "flex flex-col"
              )}
            >
              <FormSelect
                containerClassName="rounded rounded-r-none"
                name="identificationType"
                type="text"
                label="Tipo"
                options={identificationTypes}
                optionString={(identificationTypes: string) =>
                  identificationTypes
                }
                selected={idType}
                onSelectOption={setIdType}
              />
            </div>

            <div className="flex w-full flex-col">
              <FormTextInput
                className={showIdTypeInput ? "rounded rounded-l-none" : ""}
                name="clientIdentifier"
                type="text"
                label="Cédula del cliente"
                placeholder="12345678"
                onChange={formik.handleChange}
                value={cleanID(formik.values.clientIdentifier)}
                error={
                  formik.touched.clientIdentifier &&
                  formik.errors.clientIdentifier
                    ? formik.errors.clientIdentifier
                    : ""
                }
              />
            </div>
          </div>

          {/* Amount */}
          <div className="flex flex-col flex-1">
            <FormTextInput
              name="amount"
              label={`Monto a pagar (${
                formik.values.currency === "USD" ? "USD" : "VES"
              })`}
              disabled={remaining <= 0}
              onChange={(e) =>
                handlePayAmountChange(
                  e,
                  formik,
                  currencyExchange(max, exchanges, formik.values.currency)
                )
              }
              value={formik.values.amount}
              error={
                formik.touched.amount && formik.errors.amount
                  ? formik.errors.amount
                  : ""
              }
            />
          </div>

          {/* Submit */}
          <AddButton
            type="submit"
            disabled={+formik.values.amount <= 0 || loading}
          />
        </div>

        {/* Second row */}
        <div
          className={classNames(
            "flex flex-row gap-4 w-full items-end pb-2",
            !showBanksData && "hidden"
          )}
        >
          {/* Sending Bank */}
          {(formik.values.paymentMethod.paymentMethodID ===
            PaymentMethodEnum.PAGO_MOVIL_C2P ||
            (formik.values.paymentMethod.paymentMethodID ===
              PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA &&
              formik.values.destBankID === 4)) && (
            <div className="flex flex-col flex-1">
              <FormSelect
                label="Banco emisor"
                name="bank"
                options={formik.values.destBankID === 4 ? bankList : c2pBank!}
                optionString={(method) =>
                  `${formatName(method.descripcion ?? "Buscar...")}`
                }
                selected={formik.values.bank}
                onSelectOption={(bank) => formik.setFieldValue("bank", bank)}
                error={
                  formik.touched.bank && formik.errors.bank
                    ? formik.errors.bank
                    : ""
                }
              />
            </div>
          )}
          {/* Destination Bank */}
          <div
            className={classNames(
              "flex flex-col flex-1",
              !showDestBankInput && "hidden"
            )}
          >
            <FormSelect
              label="Banco destino"
              name="destBankID"
              options={formik.values.paymentMethod.bankAccounts!}
              optionString={(bank) => {
                return bank
                  ? `${formatName(bank.bankName!)} (${bank.accountNumber?.slice(
                      -4
                    )})`
                  : "Buscar...";
              }}
              selected={
                formik.values.paymentMethod.bankAccounts?.find(
                  (b) => b.bankID === formik.values.destBankID
                ) ?? null
              }
              onSelectOption={(bank) => {
                formik.setFieldValue("destBankID", bank?.bankID);
              }}
              error={
                formik.touched.destBankID && formik.errors.destBankID
                  ? formik.errors.destBankID
                  : ""
              }
            />
          </div>

          {/* Customer phone */}
          {formik.values.paymentMethod.paymentMethodID !==
            PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA &&
            formik.values.paymentMethod.paymentMethodID !==
              PaymentMethodEnum.TRANSFERENCIA &&
            formik.values.paymentMethod.paymentMethodID !==
              PaymentMethodEnum.PUNTO_DE_VENTA && (
              <div className="flex flex-col flex-1">
                <FormTextInput
                  name="phone"
                  type="text"
                  label="Teléfono"
                  placeholder="04241234567"
                  onChange={handlePhoneNumberChange}
                  value={formik.values.phone}
                  error={
                    formik.touched.phone && formik.errors.phone
                      ? formik.errors.phone
                      : ""
                  }
                />
              </div>
            )}

          {/* P2C and TRF reference */}
          {(formik.values.paymentMethod.paymentMethodID ===
            PaymentMethodEnum.PAGO_MOVIL_P2C ||
            formik.values.paymentMethod.paymentMethodID ===
              PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA ||
            formik.values.paymentMethod.paymentMethodID ===
              PaymentMethodEnum.TRANSFERENCIA) && (
            <div className="flex flex-col flex-1">
              <FormTextInput
                name="reference"
                type="text"
                label="Número de referencia"
                placeholder={
                  "Ingrese los" +
                  (formik.values.destBankID === 4 &&
                  formik.values.paymentMethod.paymentMethodID ===
                    PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA
                    ? " últimos 8 dígitos"
                    : " últimos 4 dígitos")
                }
                onChange={formik.handleChange}
                value={formik.values.reference}
                error={
                  formik.touched.reference && formik.errors.reference
                    ? formik.errors.reference
                    : ""
                }
              />
            </div>
          )}
        </div>

        {/* Third row */}
        <div
          className={classNames(
            "flex flex-row gap-4 w-full items-end pb-2",
            !showBanksData && "hidden"
          )}
        >
          {/* Date */}
          {formik.values.destBankID === 4 &&
            [
              PaymentMethodEnum.PAGO_MOVIL_P2C,
              PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA,
            ].includes(formik.values.paymentMethod.paymentMethodID) && (
              <>
                <div
                  className={classNames(
                    "flex flex-col flex-1",
                    !showDateInput && "hidden"
                  )}
                >
                  <FormSelect
                    label="Fecha"
                    name="dateOption"
                    options={[
                      PaymentDateEnum.LAST_4_DAYS,
                      PaymentDateEnum.LAST_8_DAYS,
                      PaymentDateEnum.SPECIFIC_DATE,
                    ]}
                    optionString={(date) => date}
                    selected={formik.values.dateOption}
                    onSelectOption={(date) => {
                      formik.setFieldValue("dateOption", date);
                    }}
                  />
                </div>

                <div
                  className={classNames(
                    "flex flex-col flex-1",
                    formik.values.dateOption !==
                      PaymentDateEnum.SPECIFIC_DATE && "hidden"
                  )}
                >
                  <FormDatePicker
                    id="date"
                    name="date"
                    label=""
                    useRange={false}
                    maxDate={new Date()}
                    minDate={
                      formik.values.paymentMethod.paymentMethodID ===
                      PaymentMethodEnum.PAGO_MOVIL_P2C
                        ? moment().subtract(30, "days").toDate()
                        : formik.values.paymentMethod.paymentMethodID ===
                          PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA
                        ? moment().subtract(7, "days").toDate()
                        : null
                    }
                    placeholder="Elegir fecha"
                    error={
                      formik.touched.date && formik.errors.date
                        ? formik.errors.date
                        : undefined
                    }
                    value={{
                      startDate: formik.values.date
                        ? moment(formik.values.date).toDate()
                        : null,
                      endDate: formik.values.date
                        ? moment(formik.values.date).toDate()
                        : null,
                    }}
                    toggleClassName={(oldClassname) =>
                      classNames(oldClassname, "text-indigo-600")
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        "date",
                        !!e?.startDate
                          ? moment(e.startDate).toISOString()
                          : null
                      );
                    }}
                    configs={{
                      shortcuts: {},
                    }}
                  />
                </div>
              </>
            )}

          {/* C2P OTP */}
          {formik.values.paymentMethod.paymentMethodID ===
            PaymentMethodEnum.PAGO_MOVIL_C2P && (
            <>
              <div className="flex flex-col flex-1">
                <FormTextInput
                  name="otp"
                  label="Clave C2P del cliente"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.otp}
                  error={
                    formik.touched.otp && formik.errors.otp
                      ? formik.errors.otp
                      : ""
                  }
                />
              </div>
              {formik.values.destBankID === 4 &&
                formik.values.bank.codigo === "0105" && (
                  <PrimaryButton
                    disabled={
                      formik.values.phone === "" ||
                      formik.values.clientIdentifier === ""
                    }
                    onClick={(
                      e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => handleC2PKeyRequest(e)}
                  >
                    Solicitar clave C2P
                  </PrimaryButton>
                )}
            </>
          )}
          {/* POS batch and affiliate numbers */}
          {formik.values.paymentMethod.paymentMethodID ===
            PaymentMethodEnum.PUNTO_DE_VENTA && (
            <>
              <div className="flex flex-col flex-1">
                <FormTextInput
                  name="affiliateNumber"
                  type="text"
                  label="Número de afiliado"
                  onChange={formik.handleChange}
                  value={formik.values.affiliateNumber}
                  error={
                    formik.touched.affiliateNumber &&
                    formik.errors.affiliateNumber
                      ? formik.errors.affiliateNumber
                      : ""
                  }
                />
              </div>
              <div className="flex flex-col flex-1">
                <FormTextInput
                  name="batchNumber"
                  type="text"
                  label="Número de lote"
                  onChange={formik.handleChange}
                  value={formik.values.batchNumber}
                  error={
                    formik.touched.batchNumber && formik.errors.batchNumber
                      ? formik.errors.batchNumber
                      : ""
                  }
                />
              </div>
            </>
          )}
        </div>

        {currentError && (
          <div className="flex flex-col mt-2">
            {(formik.values.paymentMethod.paymentMethodID ===
              PaymentMethodEnum.PAGO_MERCHANT_P2C ||
              formik.values.paymentMethod.paymentMethodID ===
                PaymentMethodEnum.TRANSFERENCIA ||
              formik.values.paymentMethod.paymentMethodID ===
                PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA) && (
              <p className="text-red-600 text-sm">
                Verifique que los datos y la fecha sean los correctos
              </p>
            )}

            {(formik.values.paymentMethod.paymentMethodID ===
              PaymentMethodEnum.TRANSFERENCIA ||
              formik.values.paymentMethod.paymentMethodID ===
                PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA) && (
              <p className="text-red-600 text-sm">
                Recuerde que la transferencia debe haberse hecho efectiva en la
                cuenta
              </p>
            )}
          </div>
        )}
      </form>

      {/* P2C link button */}
      {(formik.values.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.PAGO_MERCHANT_P2C ||
        formik.values.paymentMethod.paymentMethodID ===
          PaymentMethodEnum.PAGO_MOVIL_P2C) &&
        formik.values.destBankID && (
          <div>
            <LinkText
              className="text-start"
              onClick={() => setP2cModal(true)}
              text={"Datos Pago Móvil"}
            />
          </div>
        )}

      {/* Transfer link button */}
      {formik.values.paymentMethod.paymentMethodID ===
        PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA &&
        formik.values.destBankID && (
          <div>
            <LinkText
              className="text-start"
              onClick={() => setP2cModal(true)}
              text={"Datos Transferencia Inmediata"}
            />
          </div>
        )}

      {/* Payment Hints */}
      <div>
        <div className="text-sm text-gray-500 text-right">
          {formik.values.paymentMethod.paymentMethodID ===
            PaymentMethodEnum.PAGO_MOVIL_P2C && (
            <>
              {formik.values.destBankID === 6 && (
                <>
                  <p>
                    Se debe suministrar por lo menos los últimos cuatro (4)
                    dígitos de la referencia.
                  </p>
                </>
              )}
              {formik.values.destBankID === 4 && (
                <>
                  <p>
                    Se debe suministrar por lo menos los últimos cuatro (4)
                    dígitos de la referencia.
                  </p>
                  <p>
                    Es posible verificar operaciones con una antigüedad máxima
                    de treinta (30) días.
                  </p>
                </>
              )}
            </>
          )}
          {formik.values.paymentMethod.paymentMethodID ===
            PaymentMethodEnum.TRANSFERENCIA_BANCARIA_INMEDIATA && (
            <>
              {formik.values.destBankID === 6 && (
                <>
                  <p>
                    Se debe suministrar los últimos cuatro (4) dígitos de la
                    referencia.
                  </p>
                </>
              )}
              {formik.values.destBankID === 4 && (
                <>
                  <p>
                    Se debe suministrar los últimos ocho (8) dígitos de la
                    referencia.
                  </p>
                  <p>
                    Es posible verificar operaciones con una antigüedad máxima
                    de siete (7) días.
                  </p>
                </>
              )}
            </>
          )}
        </div>
      </div>

      {/* Payment modal info */}
      <PaymentInfoModal
        isOpen={p2cModal}
        onClose={() => setP2cModal(false)}
        method={formik.values.paymentMethod}
        bankID={formik.values.destBankID ?? undefined}
      />
    </>
  );
};

export default PaymentForm;
