import { FC, useMemo, useState } from "react";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import {
  alertService,
  getAccount,
  getDocument,
  getShipment,
} from "../../services";
import DocumentDetailsModal from "./DocumentDetailsModal";
import {
  DocumentInterface,
  DocumentType,
  PaymentMode,
  ShipmentInterface,
  StoreDocumentInterface,
} from "../../interfaces";
import {
  currencyExchangeText,
  documentStatusFormat,
  useCurrencyExchanges,
} from "../../utils";
import {
  ShipmentCreatePhase,
  setShipmentCreatePhase,
  setShipmentCreateDocument,
} from "../../store/slices";
import { BUCreditDTO } from "../../interfaces/Dtos/BUCreditDTO";

export enum DocumentTableField {
  ID,
  DATE,
  BILLING_DAYS,
  OWNER,
  AMOUNT,
  TO_PAID,
  EXCESS,
  DETAILS,
  STATUS,
  TYPE,
}
interface DocumentTableItemProps {
  index: number;
  document: StoreDocumentInterface;
  fields: DocumentTableField[];
  buCredit?: BUCreditDTO;
}
export const DocumentTableItem: FC<DocumentTableItemProps> = ({
  index,
  fields,
  document,
  buCredit,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const exchanges = useCurrencyExchanges();
  const [openModal, setOpenModal] = useState(false);
  const [fullDocument, setFullDocument] = useState<DocumentInterface>();

  const daysSinceCreation = useMemo(() => {
    if (!document.creationDate) return 0;

    const creationDate = new Date(document.creationDate);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate.getTime() - creationDate.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }, [document.creationDate]);

  const defeated = useMemo(() => {
    return (
      !!buCredit &&
      (document?.paymentModeID === PaymentMode.BOXOFFICE_CREDIT ||
        document?.paymentModeID === PaymentMode.DEST_BOXOFFICE_CREDIT) &&
      buCredit.creditDays < daysSinceCreation
    );
  }, [buCredit, daysSinceCreation, document?.paymentModeID]);

  const getFullDocument = async () => {
    const updatedDocument = await getDocument(document.documentID);
    if (!updatedDocument) {
      alertService.error("No se pudo obtener el documento");
      return;
    }

    const shipmentsDb: ShipmentInterface[] = [];
    for (let index = 0; index < updatedDocument.shipments.length; index++) {
      const shipmentNumber = updatedDocument.shipments[index].shipmentNumber;
      if (!!shipmentNumber) {
        let shipmentFound = await getShipment(shipmentNumber);
        if (!!shipmentFound) {
          shipmentsDb.push(shipmentFound);
        }
      }
    }

    const accountOwner =
      (await getAccount(updatedDocument.accountOwner?.id ?? "")) ??
      updatedDocument.accountOwner;

    setFullDocument({
      ...updatedDocument,
      accountOwner,
      shipments: shipmentsDb,
    });
  };

  const onPay = async () => {
    if (!fullDocument) return;

    dispatch(setShipmentCreateDocument(fullDocument));
    dispatch(setShipmentCreatePhase(ShipmentCreatePhase.PAYMENT));
    navigate("/shipment-create");
  };

  return (
    <tr
      key={index}
      className={classNames(
        "cursor-pointer hover:bg-gray-100",
        index % 2 === 0 && "bg-gray-50"
      )}
      onClick={() => {
        getFullDocument();
        setOpenModal(true);
      }}
    >
      {fields.includes(DocumentTableField.ID) && (
        <td
          className="px-4 py-4 text-xs text-gray-500 font-semibold truncate"
          style={{ maxWidth: "10rem" }}
        >
          {document.documentNumber ?? document.documentID.slice(0, 8)}

          <div onClick={(e) => e.stopPropagation()}>
            <DocumentDetailsModal
              openModal={openModal}
              document={fullDocument}
              buCredit={buCredit}
              onPay={onPay}
              setOpenModal={setOpenModal}
              setSelectedDocument={setFullDocument}
            />
          </div>
        </td>
      )}

      {fields.includes(DocumentTableField.DATE) && (
        <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
          <p>
            {!!document.creationDate &&
              new Date(document.creationDate)
                .toLocaleDateString("es-VE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })
                .replace("a", "AM")
                .replace("p", "PM")
                .slice(0, -4)}
          </p>

          {fields.includes(DocumentTableField.BILLING_DAYS) && (
            <p className="text-gray-800 font-normal">
              Días desde Facturación:{" "}
              <span className="text-gray-900 font-semibold">
                {daysSinceCreation}
              </span>
            </p>
          )}
        </td>
      )}

      {fields.includes(DocumentTableField.OWNER) && (
        <td className="px-4 py-4 text-xs text-gray-500 font-semibold">
          {document.accountName}
        </td>
      )}

      {fields.includes(DocumentTableField.AMOUNT) && (
        <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
          {currencyExchangeText(document.totalAmountBaseCurr, exchanges, "USD")}
        </td>
      )}

      {fields.includes(DocumentTableField.TO_PAID) && (
        <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
          <p
            className={classNames(
              document.documentTypeID === DocumentType.CREDIT_NOTE && "hidden"
            )}
          >
            {currencyExchangeText(
              Math.max(0, document.balanceAmountBaseCurr),
              exchanges,
              "USD"
            )}
          </p>
        </td>
      )}

      {fields.includes(DocumentTableField.EXCESS) && (
        <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
          {currencyExchangeText(
            Math.max(0, -document.balanceAmountBaseCurr),
            exchanges,
            "USD"
          )}
        </td>
      )}

      {fields.includes(DocumentTableField.DETAILS) && (
        <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
          <p>
            {document.documentTypeID === DocumentType.CREDIT_NOTE &&
              !!document.documentAppliedNumber &&
              `Factura original: ${document.documentAppliedNumber}`}
          </p>
          <p>
            {document.documentTypeID === DocumentType.CREDIT_NOTE &&
              !!document.documentAffectedNumber &&
              `Factura nueva: ${document.documentAffectedNumber}`}
          </p>
          <p>
            {document.documentTypeID !== DocumentType.CREDIT_NOTE &&
              `Cant. de guías: ${document.countShipmentDocument}`}
          </p>
        </td>
      )}

      {fields.includes(DocumentTableField.TYPE) && (
        <td className="px-4 py-4 text-xs text-gray-500 font-semibold truncate">
          {document.documentTypeID === DocumentType.CREDIT_NOTE
            ? "Nota de Crédito"
            : document.documentTypeID === DocumentType.INVOICE
            ? "Factura"
            : "Orden"}
        </td>
      )}

      {fields.includes(DocumentTableField.STATUS) && (
        <td className="px-4 py-4 text-xs text-right text-gray-500 font-semibold truncate">
          {defeated ? "Vencido" : documentStatusFormat(document.statusID)}
        </td>
      )}
    </tr>
  );
};
