import { map } from "rxjs/operators";
import { bind } from "@react-rxjs/core";
import { createSignal } from "@react-rxjs/utils";

let currentCount = 0;

const [loaderCountChange$, setLoaderCount] = createSignal<number>();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [useLoaderCount, loaderCount$] = bind(loaderCountChange$, 0);
const [useIsLoading] = bind(loaderCount$.pipe(map((count) => count > 0)));

function useStartLoader() {
  currentCount++;
  setLoaderCount(currentCount);
}

function useStopLoader() {
  currentCount = Math.max(0, currentCount - 1);
  setLoaderCount(currentCount);
}

function useStopAllLoaders() {
  currentCount = 0;
  setLoaderCount(currentCount);
}

export const loaderService = {
  start: useStartLoader,
  stop: useStopLoader,
  stopAll: useStopAllLoaders,
  useIsLoading,
};
