import { loaderService } from "../services";
import domains from "disposable-email-domains";
import { dnsLookup } from "../services/utilServices";
import phoneUtil, { PhoneNumber } from "google-libphonenumber";
import { AccountInterface, CountryInterface } from "../interfaces";

const DISPOSABLE_DOMAINS: Set<string> = new Set(domains);
const EMAIL_REGX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const PHONELIB = phoneUtil.PhoneNumberUtil.getInstance();

export const validateFullname = (fullname: string) => {
  const validCharsRegex = /^[\w\s.,'-áéíóúÁÉÍÓÚüÜ&]+$/i;
  const validAtLeastTwoWordsRegex = /^.+\s.+$/;

  if (!validCharsRegex.test(fullname)) {
    return {
      error: true,
      message:
        "El nombre sólo puede contener letras, números, guiones, puntos, comas, apóstrofes y &",
    };
  }
  if (!validAtLeastTwoWordsRegex.test(fullname)) {
    return {
      error: true,
      message: "El nombre debe tener al menos dos palabras",
    };
  }

  return { error: false, message: "" };
};

export const validateEmailRegex = (email: string) => {
  return EMAIL_REGX.test(email);
};

export const validateEmailDisposable = (email: string) => {
  const domain = email.split("@")[1];
  return !DISPOSABLE_DOMAINS.has(domain);
};

export const validateEmailDomain = async (email: string): Promise<boolean> => {
  const domain = email.split("@")[1];
  return await dnsLookup(domain);
};

export const validateEmail = async (email: string) => {
  const emailRegexValidation = validateEmailRegex(email);
  if (!emailRegexValidation) {
    return {
      error: true,
      message: "El correo electrónico es inválido",
    };
  }

  const emailDisposableValidation = validateEmailDisposable(email);
  if (!emailDisposableValidation) {
    return {
      error: true,
      message: "El correo electrónico es desechable",
    };
  }

  const emailDomainValidation = await validateEmailDomain(email);
  if (!emailDomainValidation) {
    return {
      error: true,
      message: "El dominio del correo electrónico no existe",
    };
  }

  return { error: false, message: "" };
};

export const validatePhone = (
  phone: string,
  phonePrefix: string,
  country: CountryInterface
) => {
  const phoneNumber = phonePrefix + phone;

  if (!country.countryPhoneAccessCode) {
    return { error: false, message: "" };
  }

  // Get region code for country (VE for Venezuela, US for United States, etc.)
  const regionCode = PHONELIB.getRegionCodeForCountryCode(
    Number(country.countryPhoneAccessCode)
  );

  // Parse phone number
  let parsePhone: PhoneNumber;
  try {
    parsePhone = PHONELIB.parse(phoneNumber, regionCode);
  } catch (error) {
    return { error: true, message: "El número de teléfono es inválido" };
  }

  const result = PHONELIB.isPossibleNumberWithReason(parsePhone);

  // Check if phone number is possible
  if (
    result === phoneUtil.PhoneNumberUtil.ValidationResult.INVALID_COUNTRY_CODE
  ) {
    return {
      error: true,
      message: "El código de país es inválido",
    };
  }
  if (result === phoneUtil.PhoneNumberUtil.ValidationResult.TOO_SHORT) {
    if (regionCode === "VE" && !phonePrefix) {
      return {
        error: true,
        message: "El número debe ser de 10 dígitos, Ejemplo: 2123334455",
      };
    }
    return {
      error: true,
      message: "El número de teléfono es muy corto",
    };
  }
  if (result === phoneUtil.PhoneNumberUtil.ValidationResult.TOO_LONG) {
    return {
      error: true,
      message: "El número de teléfono es muy largo",
    };
  }
  if (
    result ===
      phoneUtil.PhoneNumberUtil.ValidationResult.IS_POSSIBLE_LOCAL_ONLY ||
    result === phoneUtil.PhoneNumberUtil.ValidationResult.INVALID_LENGTH
  ) {
    return { error: true, message: "El número de teléfono es inválido" };
  }

  if (PHONELIB.isValidNumberForRegion(parsePhone, regionCode)) {
    return { error: false, message: "" };
  } else {
    return { error: true, message: "El número de teléfono es inválido" };
  }
};

export const validateIdentityDocument = (
  identityDocument: string,
  abreviationName: string
) => {
  if (abreviationName === "V-") {
    if (identityDocument.length < 6 || identityDocument.length > 9) {
      return {
        error: true,
        message: "El número de cédula debe tener entre 6 y 9 dígitos",
      };
    }
  } else if (abreviationName === "E-") {
    if (identityDocument.length < 8 || identityDocument.length > 9) {
      return {
        error: true,
        message: "El número de cédula debe tener entre 8 y 9 dígitos",
      };
    }
  } else if (abreviationName === "P-") {
    if (identityDocument.length < 5 || identityDocument.length > 10) {
      return {
        error: true,
        message: "El número de pasaporte debe tener entre 5 y 10 caracteres",
      };
    }
  } else if (abreviationName === "J-" || abreviationName === "G-") {
    if (identityDocument.length !== 9) {
      return {
        error: true,
        message: "El número de RIF debe tener 9 caracteres",
      };
    }
  } else {
    return {
      error: true,
      message: "El tipo de documento es inválido",
    };
  }

  return { error: false, message: "" };
};

export const validateAccount = async (
  //TODO update this function to return alll errors found in the account, not the first one found
  account?: AccountInterface,
  countries: CountryInterface[] = []
) => {
  if (!account) {
    return {
      error: true,
      message: "Debe ingresar una cuenta",
    };
  }

  // Validate fullname
  const fullnameValidation = validateFullname(account.accountFullName);
  if (fullnameValidation.error) {
    return fullnameValidation;
  }

  if (account.listAccountPhone.length === 0) {
    return {
      error: true,
      message: "Debe ingresar al menos un teléfono",
    };
  }

  // Validate all phone numbers
  for (
    let index = 0;
    index < Math.min(2, account.listAccountPhone.length);
    index++
  ) {
    const phone = account.listAccountPhone[index];

    const phoneValidation = validatePhone(
      phone.phoneNumber,
      "",
      countries.find((country) => country.id === phone.countryID)!
    );
    if (phoneValidation.error) {
      return phoneValidation;
    }
  }

  // Validate identification document
  const identityDocumentValidation = validateIdentityDocument(
    account.identificationNumber,
    account.abreviationName
  );
  if (identityDocumentValidation.error) {
    return identityDocumentValidation;
  }

  if (account.listAccountEmail.length === 0) {
    return {
      error: true,
      message: "Debe ingresar al menos un correo electrónico",
    };
  }

  // Validate first email
  loaderService.start();
  const emailValidation = await validateEmail(
    account.listAccountEmail[0].email
  );
  loaderService.stop();
  if (emailValidation.error) {
    return emailValidation;
  }

  return { error: false, message: "" };
};
