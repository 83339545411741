import { FC, useMemo } from "react";
import { DailyStoreOperationItem } from "./DailyStoreOperationItem";
import { DailyStoreOperationDTO } from "../../interfaces/Dtos/DailyStoreOperationDTO";

interface DailyStoreOperationTableProps {
  isDollar?: boolean;
  operations: DailyStoreOperationDTO[];
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}
export const DailyStoreOperationTable: FC<DailyStoreOperationTableProps> = ({
  operations,
  setRefresh,
  isDollar = false,
}) => {
  const sortedOperations = useMemo(() => {
    return operations.sort((a, b) => {
      return (
        new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
      );
    });
  }, [operations]);

  return (
    <table className="table-auto w-full border">
      <thead>
        <tr>
          <th className="px-4 py-2 font-semibold text-xs text-left">
            Operación
          </th>
          <th className="px-4 py-2 font-semibold text-xs text-left">
            Fecha de Registro
          </th>
          <th className="px-4 py-2 font-semibold text-xs text-left">
            Fecha de Operación
          </th>
          <th className="px-4 py-2 font-semibold text-xs text-left">
            Concepto
          </th>
          <th className="px-4 py-2 font-semibold text-xs text-left">
            Referencia
          </th>
          <th className="px-4 py-2 font-semibold text-xs text-right">
            Monto {isDollar ? "Dólares" : "Bolívares"}
          </th>
          <th className="px-4 py-2 font-semibold text-xs text-right">Estado</th>
        </tr>
      </thead>
      <tbody>
        {sortedOperations.map((operation, index) => (
          <DailyStoreOperationItem
            index={index}
            isDollar={isDollar}
            operation={operation}
            key={operation.dailyStoreOperationID}
            setRefresh={setRefresh}
          />
        ))}
      </tbody>
    </table>
  );
};
