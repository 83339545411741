import { useState, FC, useMemo, useEffect } from "react";
import classNames from "classnames";
import PaginationFooter from "../PaginationFooter";
import { StoreShipmentInterface } from "../../interfaces/Dtos";
import PendingShipmentItem from "./PendingShipmentItem";
import { FormDatePicker, FormTextInput } from "../FormFields";
import moment from "moment";
import { MixedFilter, MixedFilterDisclosure } from "../FilterDisclosure";
import {
  DeliveryType,
  PaymentMode,
  ShipmentService,
  ShipmentStatus,
} from "../../interfaces";
import {
  deliveryTypeFormat,
  paymentModeFormat,
  shipmentServiceFormat,
  shipmentStatusFormat,
} from "../../utils";
import { PrimaryButton } from "../Buttons";
import { useAppSelector } from "../../store/hooks";
import { searchShipmentFullText } from "../../services";
import LoadingIcon from "../LodingIcon";

const ROWS_PER_PAGE = 20;

const INITIAL_STATUSES = [
  ShipmentStatus.DRAFT,
  ShipmentStatus.ACTIVE,
  ShipmentStatus.DELIVERED,
  ShipmentStatus.ANNULLED,
  ShipmentStatus.REPLACED,
].map((status) => ({
  name: shipmentStatusFormat(status),
  value: status,
  checked: false,
}));

const INITIAL_DELIVERY_TYPES = [
  DeliveryType.AT_HOME,
  DeliveryType.AT_OFFICE,
  DeliveryType.INTERNATIONAL,
].map((deliveryType) => ({
  name: deliveryTypeFormat(deliveryType),
  value: deliveryType,
  checked: false,
}));

const INITIAL_PAYMENT_MODES = [
  PaymentMode.COD,
  PaymentMode.CONTADO,
  PaymentMode.CREDIT,
].map((paymentMode) => ({
  name: paymentModeFormat(paymentMode),
  value: paymentMode,
  checked: false,
}));

const INITIAL_SERVICES = [
  ShipmentService.STANDARD,
  ShipmentService.DOCUMENT,
  ShipmentService.INTERNATIONAL,
  ShipmentService.NATIONAL_LOCKER,
].map((service) => ({
  name: shipmentServiceFormat(service),
  value: service,
  checked: false,
}));

const message = (begin: number, end: number, total: number) => {
  return (
    <p className="text-sm text-gray-700">
      Mostrando resultados <span className="font-medium">{begin}</span> a{" "}
      <span className="font-medium">{end}</span> de los
      <span className="font-medium"> {total}</span> más relevantes.
    </p>
  );
};

interface ShipmentTableSearchProps {
  useBUCode?: boolean;
  forceSearch?: boolean;
}
const ShipmentTableSearch: FC<ShipmentTableSearchProps> = ({
  useBUCode,
  forceSearch,
}) => {
  const buCode = useAppSelector((state) => state.user.businessUnit?.code);

  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [statuses, setStatuses] = useState(INITIAL_STATUSES);
  const [services, setServices] = useState(INITIAL_SERVICES);
  const [maxDate, setMaxDate] = useState<Date | null>(new Date());
  const [paymentModes, setPaymentModes] = useState(INITIAL_PAYMENT_MODES);
  const [shipments, setShipments] = useState<StoreShipmentInterface[]>([]);
  const [deliveryTypes, setDeliveryTypes] = useState(INITIAL_DELIVERY_TYPES);
  const [minDate, setMinDate] = useState<Date | null>(
    moment().subtract(1, "years").toDate()
  );

  const paginatedShipments = useMemo(() => {
    return shipments.slice(itemOffset, itemOffset + ROWS_PER_PAGE);
  }, [shipments, itemOffset]);

  const filters = useMemo(() => {
    const result: MixedFilter<any>[] = [];

    result.push({
      title: "Estado",
      isMultiple: true,
      multipleOptions: statuses,
      onSelectMultipleOption: setStatuses,
    });
    result.push({
      title: "Tipo de Entrega",
      isMultiple: true,
      multipleOptions: deliveryTypes,
      onSelectMultipleOption: setDeliveryTypes,
    });
    result.push({
      title: "Modalidad de Pago",
      isMultiple: true,
      multipleOptions: paymentModes,
      onSelectMultipleOption: setPaymentModes,
    });
    result.push({
      title: "Servicio",
      isMultiple: true,
      multipleOptions: services,
      onSelectMultipleOption: setServices,
    });

    return result;
  }, [statuses, deliveryTypes, paymentModes, services]);

  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * ROWS_PER_PAGE) % shipments.length;
    setItemOffset(newOffset);
  };

  const onSearchShipments = async () => {
    if (!buCode) return;
    setLoader(true);

    const response = await searchShipmentFullText(
      query,
      useBUCode ? buCode : undefined,
      undefined,
      minDate?.toISOString(),
      maxDate ? moment(maxDate).add(1, "days").toISOString() : undefined,
      statuses.filter((s) => s.checked).map((s) => s.value),
      deliveryTypes.filter((d) => d.checked).map((d) => d.value),
      paymentModes.filter((p) => p.checked).map((p) => p.value),
      services.filter((s) => s.checked).map((s) => s.value)
    );
    if (response.didError || !response.model) {
      setShipments([]);
      setLoader(false);
      return;
    }

    setShipments(response.model);
    setLoader(false);
  };

  useEffect(() => {
    setTotalPages(Math.ceil(shipments.length / ROWS_PER_PAGE));
  }, [itemOffset, shipments]);

  useEffect(() => {
    if (forceSearch) {
      onSearchShipments();
    }
  }, [forceSearch]);

  return (
    <div className="flex flex-col max-w-full flex-1">
      {/* Filters */}
      <div className="flex flex-1 flex-col 2xl:flex-row relative gap-2 2xl:gap-10">
        <div className="flex items-center gap-4 sm:gap-10">
          <div className="flex flex-1 flex-col lg:min-w-[30rem]">
            <FormTextInput
              value={query}
              label="Buscador"
              id="shipments-search-query"
              onClick={onSearchShipments}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Buscar por número de guía o por nombre/documento de remitente o destinatario"
              data-te-toggle="tooltip"
              title="Buscar por número de guía o por nombre/documento de remitente o destinatario"
            />
          </div>

          <div className="items-end justify-end flex h-full 2xl:hidden">
            <div>
              <PrimaryButton onClick={onSearchShipments}>Buscar</PrimaryButton>
            </div>
          </div>
        </div>

        <div className="flex flex-1 gap-2 sm:gap-10 flex-col sm:flex-row">
          <div className="flex gap-4 sm:gap-10">
            <div className="w-full md:w-auto">
              <FormDatePicker
                id="date"
                name="date"
                label="Desde"
                useRange={false}
                maxDate={maxDate}
                value={{
                  startDate: minDate,
                  endDate: minDate,
                }}
                toggleClassName={(oldClassname) =>
                  classNames(oldClassname, "text-indigo-600")
                }
                onChange={(e) =>
                  setMinDate(
                    !!e?.startDate ? moment(e.startDate).toDate() : null
                  )
                }
                configs={{
                  shortcuts: {},
                }}
              />
            </div>

            <div className="w-full md:w-auto">
              <FormDatePicker
                id="date"
                name="date"
                label="Hasta"
                useRange={false}
                minDate={minDate}
                value={{
                  startDate: maxDate,
                  endDate: maxDate,
                }}
                toggleClassName={(oldClassname) =>
                  classNames(oldClassname, "text-indigo-600")
                }
                onChange={(e) =>
                  setMaxDate(
                    !!e?.startDate ? moment(e.startDate).toDate() : null
                  )
                }
                configs={{
                  shortcuts: {},
                }}
              />
            </div>
          </div>

          <div className="flex flex-col items-end justify-end">
            <MixedFilterDisclosure
              showCount
              showClear
              title="Filtros"
              filters={filters}
              className="p-0 sm:pt-10"
            />
          </div>
        </div>

        <div className="items-end justify-end hidden 2xl:flex">
          <div>
            <PrimaryButton onClick={onSearchShipments}>Buscar</PrimaryButton>
          </div>
        </div>
      </div>

      {/* Shipments */}
      <div
        className={classNames(
          "w-auto mt-8",
          (shipments.length === 0 || loader) && "hidden"
        )}
      >
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="text-left px-4 py-2 font-semibold text-xs">
                  GUÍA
                </th>

                <th className="text-left px-4 py-2 font-semibold text-xs">
                  REMITENTE
                </th>

                <th className="text-left px-4 py-2 font-semibold text-xs">
                  DESTINATARIO
                </th>

                <th className="text-left px-4 py-2 font-semibold text-xs">
                  CARACTERÍSTICAS
                </th>

                <th className="text-left px-4 py-2 font-semibold text-xs">
                  FACTURA
                </th>

                <th className="text-left px-4 py-2 font-semibold text-xs">
                  UBICACIÓN
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedShipments.map((shipment, index: number) => (
                <PendingShipmentItem
                  showLocation
                  key={index}
                  index={index}
                  shipment={shipment}
                  pendingShipments={shipments}
                />
              ))}
            </tbody>
          </table>
        </div>

        <PaginationFooter
          totalPages={totalPages}
          itemsOffSet={itemOffset}
          rowCounts={ROWS_PER_PAGE}
          totalItems={shipments.length}
          handlePageClick={handlePageClick}
          message={shipments.length === 100 ? message : undefined}
        />
      </div>

      <div
        className={classNames(
          "flex flex-1 items-center justify-center min-h-[10rem]",
          (shipments.length > 0 || loader) && "hidden"
        )}
      >
        <p className="text-gray-400">No hay guías en esta lista</p>
      </div>

      <div
        className={classNames(
          "flex items-center justify-center w-full p-8",
          !loader && "hidden"
        )}
      >
        <LoadingIcon size="2rem" />
      </div>
    </div>
  );
};

export default ShipmentTableSearch;
