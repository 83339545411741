import { Nullable } from "../interfaces";
import { TaxIdentificationTypeInterface } from "../interfaces/TaxIdentificationTypeInterface";
import api from "./api";

const baseURL = process.env.REACT_APP_API_HOST;

export const getTaxIdentificationTypes = async (): Promise<
  Nullable<TaxIdentificationTypeInterface[]>
> => {
  try {
    const result = await api.get(`${baseURL}/TaxIdentificationType`);
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {}
  return null;
};
