import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Undefinable,
  TaxInterface,
  AccountInterface,
  PaymentInterface,
  DocumentInterface,
  ShipmentInterface,
} from "../../interfaces";
import { CouponDTO } from "../../interfaces/Dtos/CouponDTO";

export enum PickupPhase {
  PAYMENT,
  DOCUMENT,
}
interface PickupState {
  phase?: PickupPhase;
  coupon?: CouponDTO;
  document?: DocumentInterface;
  owner?: AccountInterface;
  taxes: TaxInterface[];
  payments: PaymentInterface[];
  shipments: ShipmentInterface[];
}

const initialState: PickupState = {
  shipments: [],
  payments: [],
  taxes: [],
};

export const pickupSlice = createSlice({
  name: "pickup",
  initialState,
  reducers: {
    setPickupShipments: (state, action: PayloadAction<ShipmentInterface[]>) => {
      state.shipments = action.payload;
    },
    setPickupPhase: (state, action: PayloadAction<PickupPhase>) => {
      state.phase = action.payload;
    },
    setPickupOwner: (
      state,
      action: PayloadAction<Undefinable<AccountInterface>>
    ) => {
      state.owner = action.payload;
    },
    setPickupPayments: (state, action: PayloadAction<PaymentInterface[]>) => {
      state.payments = action.payload;
    },
    setPickupTaxes: (state, action: PayloadAction<TaxInterface[]>) => {
      state.taxes = action.payload;
    },
    setPickupDocument: (
      state,
      action: PayloadAction<Undefinable<DocumentInterface>>
    ) => {
      state.document = action.payload;

      // Set the other data
      state.payments = action.payload?.payments ?? [];
    },
    setPickupCoupon: (state, action: PayloadAction<CouponDTO | undefined>) => {
      state.coupon = action.payload;
    },
    clearPickUp: (state) => {
      state.phase = undefined;
      state.document = undefined;
      state.owner = undefined;
      state.coupon = undefined;
      state.taxes = [];
      state.payments = [];
      state.shipments = [];
    },
  },
});

export const {
  clearPickUp,
  setPickupTaxes,
  setPickupPhase,
  setPickupOwner,
  setPickupCoupon,
  setPickupDocument,
  setPickupPayments,
  setPickupShipments,
} = pickupSlice.actions;

export default pickupSlice.reducer;
