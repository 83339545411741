import { FC } from "react";
import SideModal from "../SideModal";
import AccountForm, { phonePrefixOptions } from "./AccountFormModal";
import { useAppSelector } from "../../store/hooks";
import { AccountInterface } from "../../interfaces";

export const VEN_CODE = "VEN";
export const CI_CODE = "001";

export interface CreateClientModalProps {
  openModal: boolean;
  idNumber?: string;
  disabledIdDocument?: boolean;
  setOpenModal: (open: boolean) => void;
  setSelectedItem: (account: AccountInterface) => void;
}

const ShipmentCreateClientModal: FC<CreateClientModalProps> = ({
  openModal,
  idNumber = "",
  disabledIdDocument,
  setOpenModal,
  setSelectedItem,
}) => {
  const countries = useAppSelector((state) => state.inmutable.countries);
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );
  return (
    <SideModal key="create" openModal={openModal} setOpenModal={setOpenModal}>
      <AccountForm
        disabledIdDocument={disabledIdDocument}
        initialValues={
          // formik initial values
          {
            fullName: "",
            email: "",
            address: "",
            country: countries.find((c) => c.countryCodeIso === VEN_CODE)!,
            phonePrefix: phonePrefixOptions.find((o) => o.value === "412")!,
            phone: "",
            country2: countries.find((c) => c.countryCodeIso === VEN_CODE)!,
            phonePrefix2: phonePrefixOptions.find((o) => o.value === "412")!,
            phone2: "",
            idType: taxIdentificationTypes.find(
              (t) => t.taxIdentificationTypeCode === CI_CODE
            )!,
            idNumber,
          }
        }
        setOpenForm={setOpenModal}
        setSelectedItem={setSelectedItem}
      />
    </SideModal>
  );
};

export default ShipmentCreateClientModal;
