import {
  BusinessUnitInterface,
  ShipmentInterface,
  ShipmentSimpleInterface,
} from "../interfaces";
import { BusinessUnitDTO } from "../interfaces/Dtos";

export const mapBusinessUnitDtoListToBusinessUnitInterface = (
  businessUnitDto: BusinessUnitDTO[]
): BusinessUnitInterface[] => {
  return businessUnitDto.map((businessUnit: BusinessUnitDTO) =>
    mapBUDtoToBUInterface(businessUnit)
  );
};

export const MapShipmentInterfaceToSimpleShipmentinterfece = (
  shipment: ShipmentInterface
): ShipmentSimpleInterface => {
  return {
    id: shipment.id ?? "",
    shipmentNumber: shipment.shipmentNumber ?? "",
    paymentMode: shipment.paymentMode,
    shipper: { accountFullName: shipment.shipper.accountFullName },
    consignee: { accountFullName: shipment.consignee.accountFullName },
    buSource: { code: shipment.buSource.code },
    buConsignee: { code: shipment.buConsignee?.code ?? "" },
    consigneeAddress: { addressName: shipment.consigneeAddress.name },
    shipperAddress: { addressName: shipment.buSource.location.name },
    status: shipment.status,
    total: shipment.total,
  };
};

export const mapBUDtoToBUInterface = (
  businessUnit: BusinessUnitDTO
): BusinessUnitInterface => {
  return {
    id: businessUnit.buid.toString(),
    name: businessUnit.buName,
    code: businessUnit.buCode,
    type: businessUnit.buTypeID,
    isLocked: businessUnit.isLocked,
    location: {
      locationID: businessUnit.locationID,
      name: businessUnit.buAreaName ?? "",
      address: businessUnit.address ?? "",
      reference: businessUnit.addressLandMark ?? "",
      postalCode: businessUnit.postalCode ?? "",
      coordinates: {
        lat: !!businessUnit.latitude ? parseFloat(businessUnit.latitude) : 0,
        lng: !!businessUnit.longitude ? parseFloat(businessUnit.longitude) : 0,
      },
    },
    polygonList: businessUnit.polygonList ?? [],
  };
};
