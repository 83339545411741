import { FC, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { PaymentInterface } from "../interfaces";
import PaginationFooter from "../components/PaginationFooter";
import HorizontalPadding from "../components/HorizontalPadding";
import PendingPaymentItem from "../components/Payment/PendingPaymentItem";
import { alertService, getStorePendingPayments, getVtid } from "../services";

const ROWS_PER_PAGE = 10;

const PendingPayments: FC = () => {
  const businessUnit = useAppSelector((state) => state.user.businessUnit);

  const [refresh, setRefresh] = useState(0);
  const [vtid, setVtid] = useState<string>();
  const [itemOffset, setItemOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [payments, setPayments] = useState<PaymentInterface[]>([]);

  const paginatedPayments = useMemo(() => {
    return payments.slice(itemOffset, itemOffset + ROWS_PER_PAGE);
  }, [payments, itemOffset]);

  // Handle page click
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * ROWS_PER_PAGE) % payments?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (!businessUnit?.code) return;

    const getPayments = async () => {
      const response = await getStorePendingPayments(businessUnit.code);

      if (response.didError || !response.model) {
        alertService.error(
          "Error al obtener los pagos pendientes",
          response.errorMessage
        );
        return;
      }

      setPayments(response.model);
    };

    getPayments();
  }, [businessUnit?.code, refresh]);

  useEffect(() => {
    const buCode = businessUnit?.code;
    if (!buCode) return;

    const saveVtid = async () => {
      const buConfigResponse = await getVtid(buCode);
      if (buConfigResponse.didError) return;

      const vtid = buConfigResponse.model?.vtid;
      if (!vtid) return;
      setVtid(vtid);
    };

    saveVtid();
  }, [businessUnit?.code]);

  // Set total pages
  useEffect(() => {
    setTotalPages(Math.ceil(payments.length / ROWS_PER_PAGE));
  }, [itemOffset, payments]);

  return (
    <main className="lg:pl-72">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32 mb-12">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          Pagos por Conciliar
        </header>
      </div>

      <HorizontalPadding>
        <div className="flex flex-col gap-4 bg-white rounded-lg border px-8 pb-6 pt-4 mb-32">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
                  PAGO
                </th>
                <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
                  ORDEN/FACTURA
                </th>
                <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
                  COMPROBANTE
                </th>
                <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
                  REFERENCIA
                </th>
                <th className="text-left px-4 py-2 font-bold text-gray-700 text-xs">
                  USUARIO
                </th>
                <th className="text-right px-4 py-2 font-bold text-gray-700 text-xs">
                  MONTO
                </th>
              </tr>
            </thead>

            <tbody>
              {paginatedPayments.map((transaction, i) => (
                <PendingPaymentItem
                  key={i}
                  index={i}
                  vtid={vtid}
                  transaction={transaction}
                  setRefresh={setRefresh}
                />
              ))}
            </tbody>
          </table>

          <PaginationFooter
            rowCounts={ROWS_PER_PAGE}
            itemsOffSet={itemOffset}
            totalItems={payments.length}
            handlePageClick={handlePageClick}
            totalPages={totalPages}
          />
        </div>
      </HorizontalPadding>
    </main>
  );
};

export default PendingPayments;
