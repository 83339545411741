import { FC, useState } from "react";
import HorizontalPadding from "../components/HorizontalPadding";
import { ActivePDocumentTable } from "../components/PDocuments/ActivePDocumentTable";
import { HistoricPDocumentTable } from "../components/PDocuments/HistoricPDcoumentTable";

const PDocuments: FC = () => {
  const [reload, setReload] = useState("");

  return (
    <main className="lg:pl-72">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32 mb-12">
        <header className="ml-4 text-2xl font-bold text-gray-700 ">
          Facturar a Casa Matriz
        </header>
      </div>

      <HorizontalPadding>
        <div className="flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
          <ActivePDocumentTable reload={reload} setReload={setReload} />
        </div>

        <div className="flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-4 mt-8 mb-32">
          <HistoricPDocumentTable reload={reload} setReload={setReload} />
        </div>
      </HorizontalPadding>
    </main>
  );
};

export default PDocuments;
