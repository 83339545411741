import api from "./api";
import { BusinessUnitInterface, ResponseInterface } from "../interfaces";
import { UserInterface } from "../interfaces/UserInterface";

const baseURLAPI = process.env.REACT_APP_API_HOST;

interface PODInsertResponse {
  PODID: string;
}

export const savePOD = async (
  shipmentId: string,
  accountID: string,
  deliveryPersonName: string,
  observation: string,
  user: UserInterface,
  businessUnit: BusinessUnitInterface
): Promise<ResponseInterface<PODInsertResponse>> => {
  const uri = `${baseURLAPI}/POD`;
  const body = JSON.stringify({
    BUCodeDelivery: businessUnit.code,
    DeliveryAuthorization: false,
    DeliveryDate: new Date().toISOString(),
    AccountID: accountID,
    ShipmentID: shipmentId,
    deliveryPersonName,
    DeliveryObservation: observation,
    CreationUser: user.userLogin,
    CreationDate: new Date().toISOString(),
  });
  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    return (error as any)?.response?.data;
  }
};
