import api from "./api";
import axios from "axios";
import { ResponseInterface } from "../interfaces";
import { CouponDTO } from "../interfaces/Dtos/CouponDTO";

const baseURLAPI = process.env.REACT_APP_API_HOST;

export const getAvailableCoupons = async (
  shipmentCount: number,
  locationID: number,
  payModalityID: number,
  deliveryTypeID: number,
  declaredValue: number,
  chargedWeight: number,
  date: string
): Promise<ResponseInterface<CouponDTO[]>> => {
  const body = JSON.stringify({
    shipmentCount,
    locationID,
    payModalityID,
    deliveryTypeID,
    declaredValue,
    chargedWeight,
    date,
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Coupon/AvailableCoupons`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: "",
      errorMessage: "Hubo un error al obtener los cupones disponibles",
    };
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const redeemCoupon = async (
  couponID: string,
  shipmentHeaderID: string,
  buCodeSource: string,
  buCodeConsignee: string,
  chargedWeight: number,
  intentDate: Date,
  userLogin: string
): Promise<ResponseInterface<CouponDTO>> => {
  const body = JSON.stringify({
    couponID,
    shipmentHeaderID,
    buCodeSource,
    buCodeConsignee,
    chargedWeight,
    intentDate,
    creationUser: userLogin,
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Coupon/RedeemCoupon`;

  try {
    const response = await api.post(uri, body, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: "",
      errorMessage: "Hubo un error al canjear el cupón",
    };
  } catch (error) {
    return (error as any)?.response?.data;
  }
};

export const verifyAccountBlackList = async (
  accountID: string,
  couponID: string
): Promise<ResponseInterface<boolean>> => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const uri = `${baseURLAPI}/Coupon/VerifyAccountBlackList?AccountID=${accountID}&CouponID=${couponID}`;

  try {
    const response = await api.get(uri, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: source.token,
    });

    if (response.status === 200) {
      return response.data;
    }
    return {
      model: null,
      didError: true,
      message: "",
      errorMessage: "Hubo un error al verificar la lista negra de los cupones",
    };
  } catch (error) {
    return (error as any)?.response?.data;
  }
};
