import { FC } from "react";
import classNames from "classnames";

export interface Tab {
  name: string;
  count?: number;
  showCount?: boolean;
  hasMore?: boolean;
}
interface MenuTabProps {
  tabs: Tab[];
  current: number;
  disabled?: boolean;
  onTabClick?: (index: number) => void;
}
const MenuTab: FC<MenuTabProps> = ({
  tabs,
  current,
  disabled = false,
  onTabClick = () => {},
}) => {
  return (
    <div className="border-b border-gray-200">
      <div className="-mb-px flex space-x-8">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={classNames(
              index === current
                ? "border-indigo-500 text-indigo-600"
                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
              "flex items-center whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium",
              !disabled && "cursor-pointer",
              disabled && "pointer-events-none opacity-75"
            )}
            onClick={() => onTabClick(index)}
          >
            {tab.name}

            {tab.showCount && (
              <span className="ml-1.5 rounded bg-indigo-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                {tab.hasMore && "+"}
                {tab.count}
              </span>
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MenuTab;
