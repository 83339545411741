//import Home from "../modules/Home";
import Profile from "../modules/Profile";
import TestLab from "../modules/TestLab";
import Login from "../modules/Auth/Login";
import CashDesk from "../modules/CashDesk";
import PDocuments from "../modules/PDocuments";
import Dashboard from "../modules/Dashboard";
import Documents from "../modules/Documents";
import Shipments from "../modules/Shipments";
import Pickup from "../modules/Pickup/Pickup";
import { ShipmentEdit } from "../modules/ShipmentEdit";
import PriceCalculator from "../modules/PriceCalculator";
import PasswordRecovery from "../modules/Auth/PasswordRecovery";
import ShipmentCreate from "../modules/Shipments/ShipmentCreate";
import UserPasswordUpdate from "../modules/Auth/UserPasswordUpdate";
import Clients from "../modules/Clients";
import PendingPayments from "../modules/PendingPayments";

interface AppRoute {
  index?: boolean;
  path?: string;
  element: React.ReactNode;
  /**
   * If the route needs authentication
   */
  auth?: boolean;
  exact?: boolean;
  rolesRequired?: string[];
}

const AppRoutes: AppRoute[] = [
  { path: "/login", element: <Login />, auth: false },
  {
    path: "/user-password-reset/:userLoginEmailToken?/:userToken?",
    element: <UserPasswordUpdate />,
    auth: false,
  },
  {
    path: "/user-password-update",
    element: <UserPasswordUpdate />,
    auth: false,
    exact: true,
  },
  { path: "/password-recovery", element: <PasswordRecovery />, auth: false },
  { index: true, path: "/*", element: <Login />, auth: false },

  { path: "/dashboard", element: <Dashboard />, auth: true },
  { path: "/shipment-create", element: <ShipmentCreate />, auth: true },
  { path: "/shipment-edit", element: <ShipmentEdit />, auth: true },
  { path: "/shipments/:shipmentNumber?", element: <Shipments />, auth: true },
  { path: "/shipments", element: <Shipments />, auth: true },
  { path: "/documents/:documentId?", element: <Documents />, auth: true },
  { path: "/documents", element: <Documents />, auth: true },
  { path: "/pending-payments", element: <PendingPayments />, auth: true },
  { path: "/estimation", element: <PriceCalculator />, auth: true },
  { path: "/pick-up", element: <Pickup />, auth: true },
  { path: "/cash-desk", element: <CashDesk />, auth: true },
  { path: "/profile", element: <Profile />, auth: true },
  {
    path: "/pdocuments",
    element: <PDocuments />,
    auth: true,
    rolesRequired: ["Administrador", "Superadministrador"],
  },
  {
    path: "/user-password-update",
    element: <UserPasswordUpdate />,
    auth: true,
    exact: true,
  },
  { index: true, path: "/*", element: <Dashboard />, auth: true },
  { path: "/test", element: <TestLab />, auth: true },
  { path: "/clients", element: <Clients />, auth: true },
];

export default AppRoutes;
