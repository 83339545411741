import { FC, useState } from "react";
import { useAppSelector } from "../store/hooks";
import { PrimaryButton } from "../components/Buttons";
import HorizontalPadding from "../components/HorizontalPadding";
import UserPasswordUpdate from "./Auth/UserPasswordUpdate";

const Profile: FC = () => {
  const user = useAppSelector((state) => state.user);
  const [showUpadatePassword, setShowUpadatePassword] = useState(false);

  return (
    <main className="lg:pl-72">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32 mb-12">
        <div>
          <header className="ml-4 text-2xl font-bold text-gray-700 ">
            Mi Perfil
          </header>
        </div>
      </div>

      <HorizontalPadding>
        <div>
          <div className="flex flex-1 flex-col bg-white rounded-lg border p-2 pb-6">
            {showUpadatePassword ? (
              <UserPasswordUpdate
                setShowUpadatePassword={setShowUpadatePassword}
              />
            ) : (
              <div className="flex flex-1 flex-col items-center sm:flex-row sm:gap-8">
                <div className="px-16 py-4">
                  <div
                    style={{ fontSize: "5rem" }}
                    className="w-48 h-48 rounded-full bg-gradient-to-b text-white from-indigo-600 to-blue-400 flex items-center justify-center text-lg font-bold"
                  >
                    {user?.user?.userName[0]} {user?.user?.userLastName[0]}
                  </div>
                </div>

                <div className="flex flex-1 flex-col sm:gap-4 w-full items-center sm:items-start sm:my-6">
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-gray-700 hidden sm:inline">
                      Nombre:
                    </span>
                    <span className="text-lg font-semibold text-gray-700">
                      {user.user?.userName} {user.user?.userLastName}
                    </span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-gray-700 hidden sm:inline">
                      Correo Electrónico:
                    </span>
                    <span className="text-lg font-semibold text-gray-700">
                      {user.user?.userEmail}
                    </span>
                  </div>

                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-gray-700 hidden sm:inline">
                      Rol:
                    </span>
                    <span className="text-lg font-semibold text-gray-700">
                      {user.user?.roleName}
                    </span>
                  </div>

                  <div className="flex flex-1 w-full mt-8 sm:mt-0 px-6 sm:px-0">
                    <PrimaryButton
                      className="w-full sm:w-48"
                      onClick={() => setShowUpadatePassword(true)}
                    >
                      Cambiar Contraseña
                    </PrimaryButton>
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-col px-6 py-4">
              <span className="text-gray-500 text-sm">
                Transferencias y Encomiendas Angulo López, C.A.
              </span>
              <span className="text-gray-500 text-xs">
                Esq al Este del Puente Soublette, Acceso a la Av. Sur 2 Bis Bis
                con calle 100 Local Galpón Nro. S/N Urb. Quinta Crespo Caracas
                Distrito Capital
              </span>
              <span className="text-gray-500 text-sm">Zona Postal 1020</span>
              <span className="text-gray-500 text-sm">
                Telf: (0212) 2807300
              </span>
              <span className="text-gray-500 text-sm">
                RIF J-00220608-0 - C.P. 10-66
              </span>
            </div>
          </div>
        </div>
      </HorizontalPadding>
    </main>
  );
};

export default Profile;
