import { useEffect, useState } from "react";
import Modal from "../components/Modal";
import LoadingIcon from "../components/LodingIcon";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { DocumentTable } from "../components/Documents/DocumentTable";
import DocumentTableSearch from "../components/Documents/DocumentTableSearch";
import { DocumentTableField } from "../components/Documents/DocumentTableItem";
import DocumentDetailsModal from "../components/Documents/DocumentDetailsModal";
import {
  getDocument,
  getShipment,
  getAccount,
  getStoreDocuments,
  alertService,
  getBUCredit,
} from "../services";
import {
  DocumentInterface,
  ShipmentInterface,
  StoreDocumentInterface,
} from "../interfaces";
import {
  ShipmentCreatePhase,
  setShipmentCreatePhase,
  setShipmentCreateDocument,
} from "../store/slices";
import { BUCreditDTO } from "../interfaces/Dtos/BUCreditDTO";

const Document = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { documentId } = useParams();
  const user = useAppSelector((state) => state.user);
  const userBU = useAppSelector((state) => state.user.businessUnit);

  const [openModal, setOpenModal] = useState(false);
  const [buCredit, setBuCredit] = useState<BUCreditDTO>();
  const [documents, setDocuments] = useState<StoreDocumentInterface[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<DocumentInterface>();

  const onPay = async () => {
    if (!selectedDocument) return;

    // Get Shipments
    const shipmentsDb: ShipmentInterface[] = [];
    for (let index = 0; index < selectedDocument.shipments.length; index++) {
      const shipmentNumber = selectedDocument.shipments[index].shipmentNumber;
      if (!!shipmentNumber) {
        let shipmentFound = await getShipment(shipmentNumber);
        if (!!shipmentFound) {
          shipmentsDb.push(shipmentFound);
        }
      }
    }

    // Get accountBillTo
    const accountOwner =
      (await getAccount(selectedDocument.accountOwner?.id ?? "")) ??
      selectedDocument.accountOwner;

    const document = {
      ...selectedDocument,
      accountOwner,
      shipments: shipmentsDb,
    };

    setSelectedDocument(document);
    // dispatch(setShipmentCreateList(shipmentsDb));
    dispatch(setShipmentCreateDocument(document));
    dispatch(setShipmentCreatePhase(ShipmentCreatePhase.PAYMENT));
    navigate("/shipment-create");
  };

  useEffect(() => {
    const getRouteDocument = async () => {
      if (!!documentId) {
        setOpenModal(true);
        const document = await getDocument(documentId);

        if (!document) {
          setOpenModal(false);
          alertService.error("No se pudo obtener el documento");
          return;
        }

        const shipmentsDb: ShipmentInterface[] = [];
        for (let index = 0; index < document.shipments.length; index++) {
          const shipmentNumber = document.shipments[index].shipmentNumber;
          if (!!shipmentNumber) {
            let shipmentFound = await getShipment(shipmentNumber);
            if (!!shipmentFound) {
              shipmentsDb.push(shipmentFound);
            }
          }
        }

        const accountOwner =
          (await getAccount(document.accountOwner?.id ?? "")) ??
          document.accountOwner;

        setSelectedDocument({
          ...document,
          accountOwner,
          shipments: shipmentsDb,
        });
      }
    };

    getRouteDocument();
  }, [documentId]);

  useEffect(() => {
    const getDocuments = async () => {
      if (!userBU) return;

      const documents = await getStoreDocuments(userBU.code);

      if (documents.didError || !documents.model) {
        alertService.error(
          "Error al cargar las facturas y órdenes",
          documents.errorMessage
        );
        return;
      }

      setDocuments(documents.model);
    };

    getDocuments();
  }, [userBU]);

  useEffect(() => {
    const buCode = user.businessUnit?.code;
    if (!buCode) return;

    const getCredit = async () => {
      const response = await getBUCredit(buCode);
      if (!response.didError && response.model) {
        setBuCredit(response.model);
      }
    };

    getCredit();
  }, [user.businessUnit]);

  return (
    <main className="lg:pl-72">
      <div className="py-6 sm:px-6 lg:px-8 bg-white relative flex items-center justify-between h-32 mb-12">
        <div>
          <header className="ml-4 text-2xl font-bold text-gray-700 ">
            Facturas y Órdenes
          </header>
        </div>
      </div>

      <div className="flex flex-1 flex-col px-8 gap-8 mb-24">
        {/* In process of creation */}
        <div className="flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
          <DocumentTable
            rowsPerPage={5}
            title="Órdenes en Proceso de Creación"
            documents={documents.filter(
              (document) => document.documentGroup === "En Creación"
            )}
            fields={[
              DocumentTableField.ID,
              DocumentTableField.DATE,
              DocumentTableField.OWNER,
              DocumentTableField.AMOUNT,
              DocumentTableField.TO_PAID,
              DocumentTableField.DETAILS,
              DocumentTableField.STATUS,
            ]}
          />
        </div>

        {/* Partial paid */}
        <div className="flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
          <DocumentTable
            rowsPerPage={5}
            title="Órdenes y Facturas por Cobrar"
            documents={documents.filter(
              (document) => document.documentGroup === "Por Cobrar"
            )}
            fields={[
              DocumentTableField.ID,
              DocumentTableField.DATE,
              DocumentTableField.OWNER,
              DocumentTableField.AMOUNT,
              DocumentTableField.TO_PAID,
              DocumentTableField.DETAILS,
              DocumentTableField.STATUS,
            ]}
          />
        </div>

        {/* Box Credit partial paid */}
        <div className="flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
          <DocumentTable
            buCredit={buCredit}
            rowsPerPage={5}
            title="Facturas de Crédito Taquilla Pendientes de Pago"
            documents={documents
              .filter(
                (document) =>
                  document.documentGroup === "Crédito Taquilla Por Cobrar"
              )
              .sort(
                (a, b) =>
                  new Date(a.creationDate).getTime() -
                  new Date(b.creationDate).getTime()
              )}
            fields={[
              DocumentTableField.ID,
              DocumentTableField.DATE,
              DocumentTableField.BILLING_DAYS,
              DocumentTableField.OWNER,
              DocumentTableField.AMOUNT,
              DocumentTableField.TO_PAID,
              DocumentTableField.DETAILS,
              DocumentTableField.STATUS,
            ]}
          />
        </div>

        {/* With excess payment */}
        <div className="flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
          <DocumentTable
            rowsPerPage={5}
            title="Facturas con Excedente de Pago"
            documents={documents.filter(
              (document) => document.documentGroup === "Excedente de Pago"
            )}
            fields={[
              DocumentTableField.ID,
              DocumentTableField.DATE,
              DocumentTableField.OWNER,
              DocumentTableField.AMOUNT,
              DocumentTableField.EXCESS,
              DocumentTableField.STATUS,
              DocumentTableField.DETAILS,
            ]}
          />
        </div>

        {/* With retentions */}
        <div className="flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
          <DocumentTable
            rowsPerPage={5}
            title="Facturas con Retenciones Pendientes"
            documents={documents.filter(
              (document) => document.documentGroup === "Retenciones Pendientes"
            )}
            fields={[
              DocumentTableField.ID,
              DocumentTableField.DATE,
              DocumentTableField.OWNER,
              DocumentTableField.AMOUNT,
              DocumentTableField.TO_PAID,
              DocumentTableField.DETAILS,
              DocumentTableField.STATUS,
            ]}
          />
        </div>

        {/* Historical */}
        <div className="flex flex-1 flex-row bg-white gap-4 rounded-lg border px-8 pb-6 pt-4">
          <DocumentTableSearch
            rowsPerPage={20}
            title="Histórico"
            fields={[
              DocumentTableField.ID,
              DocumentTableField.DATE,
              DocumentTableField.OWNER,
              DocumentTableField.AMOUNT,
              DocumentTableField.DETAILS,
              DocumentTableField.STATUS,
              DocumentTableField.TO_PAID,
            ]}
          />
        </div>
      </div>

      {!selectedDocument && documentId && (
        <Modal openModal={openModal} setOpenModal={setOpenModal}>
          <div className="flex flex-col items-center justify-center flex-1 p-8">
            <LoadingIcon size="5rem" />
            <p className="mt-2 text-xl text-center font-light text-gray-900">
              Cargando guía...
            </p>
          </div>
        </Modal>
      )}

      <DocumentDetailsModal
        openModal={openModal}
        document={selectedDocument}
        buCredit={buCredit}
        onPay={onPay}
        setOpenModal={setOpenModal}
        setSelectedDocument={setSelectedDocument}
      />
    </main>
  );
};

export default Document;
