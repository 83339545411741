import axios from "axios";

const api = axios.create();

api.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("Token");
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const baseURL = process.env.REACT_APP_API_HOST;
      try {
        const refreshTokenResponse = await axios.post(
          `${baseURL}/Account/RefreshToken`,
          {
            TokenHash: localStorage.getItem("RefreshToken"),
            UserID: localStorage.getItem("UserID"),
          }
        );
        if (refreshTokenResponse.data.error) {
          localStorage.setItem("Token", "end");
          return Promise.reject(refreshTokenResponse.data.error);
        } else {
          const newToken = refreshTokenResponse.data.tokenHash;
          localStorage.setItem("Token", newToken);
          originalRequest.headers.Authorization = `Bearer ${newToken}`;

          // Retry the original request with the new token
          return axios(originalRequest);
        }
      } catch (refreshError) {
        localStorage.setItem("Token", "end");
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
