import { FC, useMemo } from "react";
import SideModal from "../SideModal";
import { useAppSelector } from "../../store/hooks";
import { AccountInterface } from "../../interfaces";
import AccountForm, {
  emptyPhonePrefix,
  phonePrefixOptions,
} from "./AccountFormModal";

export const VEN_ID = 236;

interface UpdateClientModalProps {
  openModal: boolean;
  selectedItem: AccountInterface;
  disabledIdDocument?: boolean;
  setOpenModal: (open: boolean) => void;
  setSelectedItem: (item: AccountInterface) => void;
}
const UpdateClientModal: FC<UpdateClientModalProps> = ({
  openModal,
  setOpenModal,
  setSelectedItem,
  selectedItem,
  disabledIdDocument,
}) => {
  const countries = useAppSelector((state) => state.inmutable.countries);
  const taxIdentificationTypes = useAppSelector(
    (state) => state.inmutable.taxIdentificationTypes
  );

  const countryId = useMemo(
    () =>
      !!selectedItem.listAccountPhone
        ? selectedItem.listAccountPhone[0]?.countryID
        : VEN_ID,
    [selectedItem]
  );

  const countryId2 = useMemo(
    () =>
      !!selectedItem.listAccountPhone[1]
        ? selectedItem.listAccountPhone[1]?.countryID
        : VEN_ID,
    [selectedItem]
  );

  const getPhonePrefix = (phoneNumber: string, countryId: number) => {
    // Delete all chars that are not numbers
    phoneNumber = phoneNumber.replace(/\D/g, "");

    if (countryId === VEN_ID) {
      const prefix = phoneNumber.slice(0, 3);
      const option = phonePrefixOptions.find((p) => p.value === prefix);
      if (!!option) return option;
    }

    return emptyPhonePrefix;
  };

  const getPhoneNumber = (phoneNumber: string, countryId: number) => {
    // Delete all chars that are not numbers
    phoneNumber = phoneNumber.replace(/\D/g, "");

    const prefix = getPhonePrefix(phoneNumber, countryId).value;
    const result = phoneNumber.slice(prefix.length, phoneNumber.length);

    return result;
  };

  return (
    <SideModal openModal={openModal} setOpenModal={setOpenModal}>
      <AccountForm
        isUpdate
        ignoreTouched
        disabledIdDocument={disabledIdDocument}
        initialValues={{
          fullName: selectedItem.accountFullName,
          email: !!selectedItem.listAccountEmail?.[0]
            ? selectedItem.listAccountEmail[0]?.email
            : "",
          address: selectedItem.fiscalAddress ?? "",
          // First phone
          country:
            countries.find((c) => c.id === countryId) ??
            countries.find((c) => c.id === VEN_ID)!,
          phonePrefix: !!selectedItem.listAccountPhone?.[0]
            ? getPhonePrefix(
                selectedItem.listAccountPhone[0].phoneNumber,
                countryId
              )
            : emptyPhonePrefix,
          phone: !!selectedItem.listAccountPhone?.[0]
            ? getPhoneNumber(
                selectedItem.listAccountPhone[0].phoneNumber,
                countryId
              )
            : "",

          // Second phone
          country2: countries.find((c) => c.id === countryId2),
          phonePrefix2: !!selectedItem.listAccountPhone?.[1]
            ? getPhonePrefix(
                selectedItem.listAccountPhone[1].phoneNumber,
                countryId2
              )
            : phonePrefixOptions.find((o) => o.value === "412")!,
          phone2: !!selectedItem.listAccountPhone?.[1]
            ? getPhoneNumber(
                selectedItem.listAccountPhone[1].phoneNumber,
                countryId2
              )
            : undefined,

          idType: taxIdentificationTypes.find(
            (idType) => selectedItem.abreviationName === idType.abreviationName
          )!,
          idNumber: selectedItem.identificationNumber,
        }}
        accountItem={selectedItem}
        setOpenForm={setOpenModal}
        setSelectedItem={setSelectedItem}
      />
    </SideModal>
  );
};

export default UpdateClientModal;
