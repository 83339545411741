import React, { FC, useEffect, useState } from "react";
import Modal from "../Modal";
import { FormFileUpload, FormTextInput } from "../FormFields";
import { PaymentInterface } from "../../interfaces";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { currencyExchangeText, useCurrencyExchanges } from "../../utils";
import { UserInterface } from "../../interfaces/UserInterface";
import { useAppSelector } from "../../store/hooks";

interface ModalProps {
  retention?: PaymentInterface;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  onSaveProofOfPayment: (
    file: File,
    retention: PaymentInterface,
    user: UserInterface,
    statusID: number,
    reference?: string,
    isModification?: boolean
  ) => void;
  isModification?: boolean;
  setIsModification?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProofOfPaymentModal: FC<ModalProps> = ({
  retention,
  openModal,
  setOpenModal,
  onSaveProofOfPayment,
  isModification,
  setIsModification,
}) => {
  const exchanges = useCurrencyExchanges();
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<string>("");
  const [reference, setReference] = useState<string>("");
  const user = useAppSelector((state) => state.user.user);

  const handleFileChange = (file: File[]) => {
    // Verify that the file is valid (PDF or image)
    if (
      file &&
      (file[0].type === "application/pdf" || file[0].type.startsWith("image/"))
    ) {
      setError("");
      setFile(file[0]);
    } else if (!!file) {
      setError("El archivo debe ser formato PDF o imagen");
    }
  };

  const handleSubmit = async () => {
    if (!file) return;

    onSaveProofOfPayment(
      file,
      retention!,
      user!,
      51,
      reference,
      isModification
    );
    setOpenModal(false);
    setIsModification?.(false);
  };

  useEffect(() => {
    setError("");
  }, [openModal]);

  return (
    <Modal openModal={openModal} setOpenModal={setOpenModal}>
      {/* Header */}
      <div className="flex flex-row justify-between mb-4 gap-16">
        <h2 className="text-xl font-bold">Subir comprobante de retención</h2>

        <div className="ml-3 flex h-7 items-center">
          <button
            type="button"
            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onClick={() => setOpenModal(false)}
          >
            <span className="absolute -inset-2.5" />
            <span className="sr-only">Close panel</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>

      {/* Body */}
      <div>
        <div className="flex flex-col flex-1 mb-2">
          <p>{retention?.paymentMethod.paymentMethodName}</p>
          <p className="text-gray-500">
            {currencyExchangeText(retention?.amount ?? 0, exchanges, "USD")}
          </p>
          <p className="text-gray-500 text-xs">
            ({currencyExchangeText(retention?.amount ?? 0, exchanges, "BS")})
          </p>
        </div>
        <div className="flex flex-1 py-4 flex-col md:flex-row">
          <div className="w-full">
            <FormTextInput
              name="proofOfPayment"
              label="Número de Comprobante"
              value={reference}
              error={error}
              onChange={(e) => setReference(e.target.value)}
            />
          </div>
        </div>

        <FormFileUpload
          error={error}
          description="El archivo debe ser formato PDF o imagen"
          onSelectFile={handleFileChange}
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
        />
      </div>

      {/* Footer */}
      <div className="flex flex-row gap-4 items-center justify-between mt-8">
        <SecondaryButton
          onClick={() => setOpenModal(false)}
          className="px-4 py-2"
        >
          Cancelar
        </SecondaryButton>

        <PrimaryButton
          disabled={!file || !!error}
          onClick={handleSubmit}
          className="px-8"
        >
          Subir
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default ProofOfPaymentModal;
