import { FC, useMemo, useState } from "react";
import moment from "moment";
import Modal from "../Modal";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { formatDailyOperationStatus } from "../../utils";
import { LinkText, PrimaryButton, SecondaryButton } from "../Buttons";
import {
  DailyOperationStatus,
  DailyTransactionType,
  DailyStoreOperationDTO,
} from "../../interfaces/Dtos/DailyStoreOperationDTO";
import {
  alertService,
  loaderService,
  previewFileDO,
  updateOperationStatus,
} from "../../services";

interface DailyStoreOperationModalProps {
  open: boolean;
  operation: DailyStoreOperationDTO;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}
export const DailyStoreOperationModal: FC<DailyStoreOperationModalProps> = ({
  operation,
  open,
  setOpen,
  setRefresh,
}) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleRejectOperation = async () => {
    loaderService.start();
    const response = await updateOperationStatus(
      operation.dailyStoreOperationID,
      DailyOperationStatus.ANULLED,
      user?.userLogin!
    );
    loaderService.stop();
    setRefresh(Math.random());

    if (!response || response.didError) {
      alertService.error("No se pudo rechazar la operación");
      return;
    }
  };

  const annullable = useMemo(() => {
    return (
      operation.transactionTypeID === DailyTransactionType.DEPOSIT &&
      moment(operation.creationDate).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD") &&
      operation.operationStatusID === DailyOperationStatus.PENDING &&
      !operation.applied
    );
  }, [
    operation.transactionType,
    operation.operationDate,
    operation.operationStatusID,
    operation.applied,
  ]);

  return (
    <Modal openModal={open} setOpenModal={setOpen} className="min-w-[35rem]">
      {/* Header */}
      <div className="flex flex-row justify-between mb-4">
        <h2 className="text-xl font-bold">
          Operación{" "}
          <span className="text-indigo-600">
            {operation.dailyStoreOperationID}
          </span>
        </h2>
        <div className="ml-3 flex h-7 items-center">
          <button
            type="button"
            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            onClick={() => setOpen(false)}
          >
            <span className="absolute -inset-2.5" />
            <span className="sr-only">Close panel</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>

      {/* Body */}
      <div className="flex flex-col w-full text-sm mb-5">
        <div className="flex w-full items-center gap-8">
          <p className="flex-1 font-bold">Tipo de transacción</p>
          <p className="flex-1">{operation.transactionType}</p>
        </div>

        <div className="flex w-full items-center gap-8">
          <p className="flex-1 font-bold">Monto (VES)</p>
          <p className="flex-1">
            {Math.abs(operation.expenses - operation.income)}
          </p>
        </div>

        <div
          className={classNames(
            "flex w-full items-center gap-8",
            !operation.bankName && "hidden"
          )}
        >
          <p className="flex-1 font-bold">Banco</p>
          <p className="flex-1">{operation.bankName}</p>
        </div>

        <div
          className={classNames(
            "flex w-full items-center gap-8",
            !operation.referenceNum && "hidden"
          )}
        >
          <p className="flex-1 font-bold">Número de Comprobante</p>
          <p className="flex-1">{operation.referenceNum}</p>
        </div>

        <div className="flex w-full items-center gap-8">
          <p className="flex-1 font-bold">Fecha</p>
          <p className="flex-1">
            {moment(operation.operationDate).format("YYYY-MM-DD")}
          </p>
        </div>

        <div
          className={classNames(
            "flex w-full items-center gap-8",
            !operation.attachmentID && "hidden"
          )}
        >
          <p className="flex-1 font-bold">Adjunto</p>
          <LinkText
            text="Enlace"
            className="flex-1"
            onClick={() => previewFileDO(operation.attachmentID!)}
          />
        </div>

        <div className="flex w-full items-center gap-8">
          <p className="flex-1 font-bold">Estado</p>
          <p className="flex-1">
            {formatDailyOperationStatus(operation.operationStatusID)}
          </p>
        </div>

        <div
          className={classNames(
            "flex w-full items-center gap-8",
            !operation.documentID && "hidden"
          )}
        >
          <p className="flex-1 font-bold">Factura asociada</p>
          <LinkText
            text="Enlace"
            className="flex-1"
            onClick={() => navigate(`/documents/${operation.documentID}`)}
          />
        </div>
      </div>

      {/* Actions */}
      <div className="flex w-full justify-end">
        <PrimaryButton
          onClick={() => setOpenConfirmationModal(true)}
          className={classNames(!annullable && "hidden")}
        >
          Anular
        </PrimaryButton>
      </div>

      <Modal
        openModal={openConfirmationModal}
        setOpenModal={setOpenConfirmationModal}
      >
        <div className="flex flex-col gap-4 max-w-[20rem]">
          <p className="text-xl text-center">
            ¿Está seguro que desea anular la operación{" "}
            <span className="font-semibold text-indigo-600">
              {operation.dailyStoreOperationID}
            </span>
            ?
          </p>

          <div className="flex w-full justify-between mt-6 gap-4">
            <SecondaryButton
              onClick={() => setOpenConfirmationModal(false)}
              className="w-40"
              type="button"
            >
              Cancelar
            </SecondaryButton>

            <PrimaryButton
              onClick={() => {
                setOpenConfirmationModal(false);
                handleRejectOperation();
              }}
              className="w-40"
              type="button"
            >
              Anular
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};
