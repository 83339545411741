import { FC, useState } from "react";
import "moment/locale/es";
import * as Yup from "yup";
import moment from "moment";
import { Formik } from "formik";
import classNames from "classnames";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { BankAccountDTO } from "../../interfaces/Dtos/BankAccountDTO";
import Modal from "../Modal";
import {
  FormDatePicker,
  FormFileUpload,
  FormSelect,
  FormTextInput,
} from "../FormFields";
import { formatAmount, formatFloat } from "../../utils";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { loaderService } from "../../services";

const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Este campo es requerido")
    .min(0.01, "El monto debe ser mayor a 0"),
  bank: Yup.object<BankAccountDTO>().required("Este campo es requerido"),
  proofOfPayment: Yup.string().required("Este campo es requerido"),
  date: Yup.date().required("Este campo es requerido"),
  file: Yup.mixed<File>().required("Este campo es requerido"),
});

export interface DeposiValues {
  amount: number;
  bank: BankAccountDTO | null;
  proofOfPayment: string;
  date: Date;
  file: File;
}
interface DepositModalProps {
  title: string;
  open: boolean;
  isDollar?: boolean;
  bankAccounts: BankAccountDTO[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (values: DeposiValues) => Promise<void>;
}
export const DepositModal: FC<DepositModalProps> = ({
  title,
  open,
  bankAccounts,
  isDollar = false,
  setOpen,
  onSubmit,
}) => {
  const loading = loaderService.useIsLoading();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  return (
    <Modal
      openModal={open}
      setOpenModal={setOpen}
      className="flex flex-1 flex-col mx-0 sm:mx-4 md:mx-0"
      style={{ maxWidth: "45rem" }}
    >
      <div className="flex justify-between">
        <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>

        <button
          type="button"
          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          onClick={() => setOpen(false)}
        >
          <span className="sr-only">Close panel</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <hr className="my-4" />

      <div className="bg-white p-4 flex-1">
        <Formik
          initialValues={{
            amount: "",
            bank: null as BankAccountDTO | null,
            proofOfPayment: "",
            date: undefined as Date | undefined,
            file: undefined as File | undefined,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            await onSubmit({
              ...values,
              bank: values.bank!,
              amount: +values.amount,
              date: values.date!,
              file: values.file!,
            });
            resetForm();
          }}
        >
          {(formik) => (
            <div className="flex flex-1 flex-col gap-4">
              <div className="flex flex-1 gap-4 flex-col md:flex-row">
                <div className="w-full">
                  <FormTextInput
                    name="amount"
                    label={`Monto (${isDollar ? "USD" : "VES"})`}
                    value={formik.values.amount}
                    error={
                      formik.touched.amount && formik.errors.amount
                        ? formik.errors.amount
                        : undefined
                    }
                    onChange={(e) => {
                      const setValue = (value: string) => {
                        e.target.value = value;
                      };
                      if (!formatFloat(e.target.value, setValue)) {
                        return;
                      }

                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div className="w-full">
                  <FormSelect
                    name="bank"
                    label="Banco"
                    options={bankAccounts}
                    selected={formik.values.bank}
                    error={
                      formik.touched.bank && formik.errors.bank
                        ? formik.errors.bank
                        : undefined
                    }
                    optionString={(bank) =>
                      bank ? `${bank.bank} (${bank.accountCode})` : ""
                    }
                    onSelectOption={(bank) =>
                      formik.setFieldValue("bank", bank)
                    }
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>

              <div className="flex flex-1 gap-4 flex-col md:flex-row">
                <div className="w-full">
                  <FormTextInput
                    name="proofOfPayment"
                    label="Número de Comprobante"
                    value={formik.values.proofOfPayment}
                    error={
                      formik.touched.proofOfPayment &&
                      formik.errors.proofOfPayment
                        ? formik.errors.proofOfPayment
                        : undefined
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>

                <div className="w-full">
                  <FormDatePicker
                    id="date"
                    name="date"
                    label="Fecha"
                    useRange={false}
                    minDate={moment().subtract(30, "days").toDate()}
                    maxDate={new Date()}
                    error={
                      formik.touched.date && formik.errors.date
                        ? formik.errors.date
                        : undefined
                    }
                    value={{
                      startDate: formik.values.date ?? null,
                      endDate: formik.values.date ?? null,
                    }}
                    toggleClassName={(oldClassname) =>
                      classNames(oldClassname, "text-indigo-600")
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        "date",
                        !!e?.startDate ? moment(e.startDate).toDate() : null
                      );
                    }}
                    configs={{
                      shortcuts: {},
                    }}
                  />
                </div>
              </div>

              <div style={{ maxHeight: "20rem" }}>
                <FormFileUpload
                  error={
                    formik.touched.file && formik.errors.file
                      ? formik.errors.file
                      : undefined
                  }
                  zoneClassName="!h-40"
                  description="El archivo debe ser formato PDF o imagen"
                  onSelectFile={(file) => {
                    if (
                      file &&
                      (file[0].type === "application/pdf" ||
                        file[0].type.startsWith("image/"))
                    ) {
                      formik.setFieldValue("file", file[0]);
                    } else if (!!file) {
                      formik.setFieldError(
                        "file",
                        "El archivo debe ser formato PDF o imagen"
                      );
                    }
                  }}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50"
                />
              </div>

              <hr className="mt-4 mb-2" />

              <div className="flex flex-1 gap-4 justify-between">
                <SecondaryButton
                  onClick={() => setOpen(false)}
                  className="w-32"
                >
                  Cancelar
                </SecondaryButton>

                <PrimaryButton
                  disabled={loading}
                  onClick={async () => {
                    const errors = await formik.validateForm();
                    if (Object.values(errors).length == 0) {
                      setOpenConfirmModal(true);
                    } else {
                      formik.submitForm();
                    }
                  }}
                  className="w-32"
                >
                  Registrar
                </PrimaryButton>
              </div>

              <Modal
                openModal={openConfirmModal}
                setOpenModal={setOpenConfirmModal}
                className="w-full max-w-[35rem]"
              >
                <div className="flex flex-col items-center justify-center">
                  <p className="mt-2 text-lg text-center text-gray-900 font-medium">
                    ¿Estás seguro que deseas registrar este depósito?
                  </p>

                  <div className="flex w-full items-center gap-8 mt-4">
                    <p className="flex-1 font-medium">
                      Monto ({isDollar ? "USD" : "VES"})
                    </p>
                    <p className="flex-1">
                      {formatAmount(Number.parseFloat(formik.values.amount))}
                    </p>
                  </div>

                  <div className="flex w-full items-center gap-8">
                    <p className="flex-1 font-medium">Banco</p>
                    <p className="flex-1">
                      {formik.values.bank?.bank} (
                      {formik.values.bank?.accountCode})
                    </p>
                  </div>

                  <div className="flex w-full items-center gap-8">
                    <p className="flex-1 font-medium">Número de Comprobante</p>
                    <p className="flex-1">{formik.values.proofOfPayment}</p>
                  </div>

                  <div className="flex w-full items-center gap-8">
                    <p className="flex-1 font-medium">Fecha</p>
                    <p className="flex-1">
                      {moment(formik.values.date).format("YYYY-MM-DD")}
                    </p>
                  </div>

                  <div className="flex w-full items-center gap-8 mb-4">
                    <p className="flex-1 font-medium">Archivo</p>
                    <p className="flex-1">{formik.values.file?.name}</p>
                  </div>

                  <div className="mt-4 w-full flex flex-row items-center justify-between gap-4">
                    <SecondaryButton
                      className="px-4 w-32"
                      onClick={() => setOpenConfirmModal(false)}
                    >
                      Cancelar
                    </SecondaryButton>

                    <PrimaryButton
                      className="px-4 w-32"
                      disabled={loading}
                      onClick={() => {
                        setOpenConfirmModal(false);
                        formik.submitForm();
                      }}
                    >
                      Aceptar
                    </PrimaryButton>
                  </div>
                </div>
              </Modal>
            </div>
          )}
        </Formik>
      </div>
    </Modal>
  );
};
