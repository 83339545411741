import axios from "axios";
import { MerchantInterface } from "../interfaces";
import { Nullable } from "../interfaces/GenericTypes";
import { merchantFormat } from "../utils";

const testURL = "http://localhost:8085/vpos/ping";
const baseURL = "http://localhost:8085/vpos/metodo";

// Merchant misc options
enum MiscelaneousMerchantMethods {
  "imprimeUltimoVoucher",
  "imprimeUltimoVoucherP",
  "precierre",
  "cierre",
  "ultimoCierre",
}

// Request header
const header = {
  headers: {
    "Content-Type": "application/json",
  },
};

// Ping merchant service
export const pingMerchant = async (): Promise<Nullable<MerchantInterface>> => {
  try {
    const response = await axios.get(testURL);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

// Pay through merchant service
export const payMerchant = async (
  amount: number,
  ci: string
): Promise<Nullable<MerchantInterface>> => {
  const amountString = merchantFormat(amount);

  const body = {
    accion: "tarjeta",
    montoTransaccion: amountString,
    cedula: ci,
  };
  try {
    const response = await axios.post(baseURL, body, header);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

export const annulationMerchant = async (
  seq: string
): Promise<Nullable<MerchantInterface>> => {
  const body = {
    accion: "anulacion",
    numSeq: seq,
  };
  try {
    const response = await axios.post(baseURL, body, header);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

// Pay change with merchant service
export const changeMerchant = async (
  amount: number,
  ci: string
): Promise<Nullable<MerchantInterface>> => {
  const amountString = merchantFormat(amount);
  const body = {
    accion: "cambio",
    montoTransaccion: amountString,
    cedula: ci,
    tipoMoneda: "VES",
  };
  try {
    const response = await axios.post(baseURL, body, header);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};

// Use other merchant services
export const miscMerchant = async (
  command: number
): Promise<Nullable<MerchantInterface>> => {
  const body = {
    accion: MiscelaneousMerchantMethods[command],
  };
  try {
    const response = await axios.post(baseURL, body, header);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {}

  return null;
};
