import { useAppSelector } from "../store/hooks";
import { formatAmount } from "./format";

export type Currency = "USD" | "BS";
export type CurrencyExchange = {
  symbol: string;
  exchange: number;
};
export type CurrencyExchanges = {
  [key in Currency]: CurrencyExchange;
};

export const useCurrencyExchanges = () => {
  const dollarExchange = 1;
  const bsExchange = useAppSelector(
    (state) => state.inmutable.exchangeRate
  ).exchangeRate;

  const result: CurrencyExchanges = {
    USD: {
      symbol: "$",
      exchange: dollarExchange,
    },
    BS: {
      symbol: "Bs",
      exchange: bsExchange,
    },
  };

  return result;
};

export const currencyExchange = (
  amount: number,
  exchanges: CurrencyExchanges,
  to: Currency,
  from: Currency = "BS"
): number => {
  const exchange = exchanges[to].exchange / exchanges[from].exchange;
  return amount * exchange;
};

export const currencyExchangeText = (
  amount: number,
  exchanges: CurrencyExchanges,
  to: Currency,
  from: Currency = "BS"
): string => {
  const result = formatAmount(currencyExchange(amount, exchanges, to, from));

  const symbol = exchanges[to].symbol;

  return `${symbol} ${result}`;
};
